<template>
    <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Limpeza de Registros</h4>
        <el-table :data="cleaningLogs">
            <el-table-column prop="Type" label="Tipo">
                <template slot-scope="scope">
                    <base-input>
                        <el-input style="text-align:center" disabled
                            :value="getCleaningLogType(scope.row.Type)"></el-input>
                    </base-input>
                </template>
            </el-table-column>
            <el-table-column prop="Frequency" label="Tempo">
                <template #header>
                    <span>
                        Tempo
                        <el-tooltip
                            content="Tempo em dias que os dados permanecerão armazenados no banco de dados. Após esse período, os dados mais antigos serão removidos automaticamente."
                            effect="light" :open-delay="100" placement="top">
                            <i class="material-icons ml-1 mb-1 icon-custom">info_outline</i>
                        </el-tooltip>
                    </span>
                </template>
                <template slot-scope="scope">
                    <base-input>
                        <el-input style="text-align:center" v-model="scope.row.Frequency"></el-input>
                    </base-input>
                </template>
            </el-table-column>
            <el-table-column prop="LastRun" label="Última execução">
                <template slot-scope="scope">
                    <base-input>
                        <el-date-picker type="date" v-model="scope.row.LastRun" disabled
                            format="dd/MM/yyyy"></el-date-picker>
                    </base-input>
                </template>
            </el-table-column>
            <el-table-column prop="Active" label="Status">
                <template slot-scope="scope">
                    <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                        v-model="scope.row.Active" :sync="true" />
                </template>
            </el-table-column>
        </el-table>
        <button class="row btn btn-danger mx-3" @click="startGoLive" :loading="loading">Realizar Go-Live</button>
    </card>
</template>
<script>
import { mapState, mapActions } from 'vuex';
import constants from '@/util/constants';
import swal from "sweetalert2";

export default {
    computed: {
        ...mapState("cleaningLog", ["customEventCurrent"]),
    },
    props: {
        cleaningLogs: {
            type: Array,
            default: function () {
                return [{
                    Id: "",
                    Type: "",
                    Frequency: "",
                    LastRun: "",
                    Active: false
                }];
            },
        }
    },
    data() {
        return {
            loading: false
        }
    },
    methods: {
        ...mapActions("cleaningLog", ["startProcessGoLive", "getLogs"]),

        dateFormatter(row, col, value, index) {
            return moment.utc(value).format("DD/MM/YYYY HH:mm:ss");
        },
        getCleaningLogType(key) {
            return constants.CleaningLogType.find(t => t.Key === key).Label;
        },
        startGoLive() {
            swal({
                title: "Gostaria de preparar o Go Live?",
                text: "Esse processo não poderá ser revertido. Os dados serão apagados e não poderão ser recuperados. Tem certeza que deseja continuar?",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                cancelButtonText: "Não",
                confirmButtonText: "Sim"
            }).then(async result => {
                if (result.value) {
                    swal({
                        title: "Confirmação Adicional",
                        text: "Após o processo os dados do ambiente serão removidos, tem total certeza que deseja continuar? Não será possível reverter essa ação.",
                        showCancelButton: true,
                        confirmButtonClass: "btn btn-success btn-fill",
                        cancelButtonClass: "btn btn-danger btn-fill",
                        cancelButtonText: "Não",
                        confirmButtonText: "Sim"
                    }).then(async result => {
                        if (result.value) {
                            this.loading = true;
                            this.startProcessGoLive()
                                .then(response => {
                                    this.getLogs().then(response => {
                                        this.loading = false;
                                        swal({
                                            title: "Sucesso",
                                            text: "Go Live finalizado com sucesso",
                                            type: "success",
                                            confirmButtonClass: "btn btn-success btn-fill",
                                            buttonsStyling: false
                                        });
                                    });
                                })
                                .catch(error => {
                                    this.loading = false;
                                    swal({
                                        title: "Atenção",
                                        text: "Ocorreu um erro ao executar o Go Live. Por favor, tente novamente.",
                                        type: "error",
                                        confirmButtonClass: "btn btn-success btn-fill",
                                        buttonsStyling: false
                                    });
                                });
                        }
                    });
                }
            });
        }
    }
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
    border-color: #00ADB7 !important;
    background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
    color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
    color: #00ADB7;
    font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed !important;
}
</style>