<template>
  <transition name="fade" mode="out-in">
    <div v-if="!showDetails">
      <div>
        <h4 class="card-title">Importar arquivo com relação de métodos de envio</h4>
        <div class="upload">
          <ul>
            <li v-for="(file) in files" :key="file.id">
              <span>{{ file.name }}</span> -
              <span>{{ file.size | formatSize }}</span>
            </li>
          </ul>
          <div class="example-btn">
            <file-upload class="btn btn-primary" extensions="xlsx" accept=".xlsx" :multiple="false"
              :size="1024 * 1024 * 10" v-model="files" @input-filter="inputFilter" @input-file="inputFile" ref="upload">
              <i class="fa fa-plus"></i>
              Selecionar Arquivo
            </file-upload>
            <button type="button" class="btn btn-success" v-if="!$refs.upload || !$refs.upload.active"
              @click.prevent="$refs.upload.active = true">
              <i class="fa fa-arrow-up" aria-hidden="true"></i>
              Começar Importação
            </button>
            <button type="button" class="btn btn-danger" v-else @click.prevent="$refs.upload.active = false">
              <i class="fa fa-stop" aria-hidden="true"></i>
              Parar importação
            </button>
            <a class="ml-2 btn btn-info" :href="templateImport" download>
              <i class="fa fa-arrow-down" aria-hidden="true"></i> Baixar Template
            </a>
          </div>
        </div>
        <div class="pt-5">
        </div>
      </div>
      <card card-body-classes="table-full-width">
        <h4 slot="header" id="v-step-20" class="card-title">Métodos de envio</h4>
        <div>
          <div class="row" style="float: right">
            <span style="margin: 0 10pt">
              <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome"
                  v-model="Filters.name"></el-input>
              </base-input>
            </span>

            <span style="margin: 0 10pt">
              <base-input>
                <el-select class="select-primary mb-3 pagination-select" v-model="Filters.type" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in Filters.typeOptions" :key="item.name"
                    :label="item.name" :value="item.value"></el-option>
                </el-select>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="FilterShippingMethods()">
                Filtrar
              </button>
            </span>

            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="GetShippingMethods()">
                Limpar filtro
              </button>
            </span>

            <div>
              <span style="margin: 0 10pt">
                <button class="custom-btn-add" @click="AddNew()"
                  v-if="getUserPermission('38_c')">Novo</button>
              </span>
            </div>
          </div>

          <svg v-if="showSpinner" class="spinner-lg" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>

          <el-table :data="queriedData">
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
              :prop="column.prop" :label="column.label" align="center"></el-table-column>
            <el-table-column :min-width="135" align="right" label="Ações">
              <div slot-scope="props">
                <base-button class="like !important btn-link" type="primary" size="sm" icon
                  @click="ShowDetails(props.row)" v-if="getUserPermission('38_c')">
                  <i class="material-icons">mode_edit_outline</i>
                </base-button>
                <base-button class="like !important btn-link" type="danger" size="sm" icon @click="Delete(props.row)"
                  v-if="getUserPermission('38_c')">
                  <i class="material-icons">delete_outline</i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="
            col-12
            d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
          <div class="col-5"></div>
          <div class="col-2">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>
          <div class="col-3">
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
              :per-page="pagination.perPage" :total="total"></base-pagination>
          </div>
          <div class="col-2">
            <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
              v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </card>
    </div>

    <div class="row" v-else>
      <div class="col-12">
        <h4 slot="header" class="card-title">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="HideDetails()">
            <i class="material-icons">arrow_back</i> </base-button>Detalhes do método de envio
        </h4>
      </div>
      <div class="col-4">
        <card type="tasks" class="stacked-form">
          <h4 class="card-title">Nome do Método de Envio</h4>
          <div class="col-8">
            <base-input>
              <el-input placeholder="Informe o Nome do Método de Envio" v-model="ShippingMethod.Name"></el-input>
            </base-input>
          </div>
          <h4 class="card-title">Referência no E-Commerce</h4>
          <div class="col-8">
            <base-input>
              <el-input placeholder="Informe a Referência no E-Commerce"
                v-model="ShippingMethod.EcommerceReference"></el-input>
            </base-input>
          </div>

          <h4 class="card-title">Referência no HUB</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input placeholder="Informe a Referência no HUB" v-model="ShippingMethod.HubReference"></el-input>
            </base-input>
          </div>
        </card>
      </div>
      <div class="col-4">
        <card type="tasks" class="stacked-form">
          <h4 class="card-title">Referência no ERP</h4>
          <svg v-if="showSpinner" class="spinner-sm" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
          <div class="table-full-width table-responsive" v-if="!enableErpReferenceManual">
            <ul>
              <li v-for="item in erpTable" :key="item.Code" class="custom-list-item">
                <base-radio v-model="ShippingMethod.ErpReference" :name="item.Name">&nbsp;{{ item.Name }}</base-radio>
              </li>
            </ul>
          </div>
          <div class="table-full-width table-responsive" v-else>
            <base-input>
              <el-input placeholder="Referência no ERP" v-model="ShippingMethod.ErpReference"></el-input>
            </base-input>
          </div>
        </card>
      </div>

      <div class="col-4">
        <card type="tasks" class="stacked-form">
          <h4 class="card-title">Informe a Transportadora</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input placeholder="Informe a Transportadora" v-model="ShippingMethod.CarrierCode"></el-input>
            </base-input>
          </div>
          <h4 class="card-title">ID Incoterms</h4>
          <div class="col-12 table-full-width table-responsive">
            <div>
              <base-input>
                <el-select v-if="isSapBusinessOne" class="select-primary" v-model="ShippingMethod.IdIncoterms"
                  placeholder="Informe o ID Incoterms" filterable>
                  <el-option v-for="item in icotermOptions" :key="item.value" :label="item.label" :value="item.value">
                  </el-option>
                </el-select>
                <el-select v-else class="select-primary" v-model="ShippingMethod.IdIncoterms"
                  placeholder="Informe o ID Incoterms" filterable>
                  <el-option v-for="item in IcotermsS4Hana" :key="item.Value" :label="item.Label" :value="item.Value">
                  </el-option>
                </el-select>
              </base-input>
            </div>
          </div>
          <!--
          <h4 class="card-title">Local Incoterms</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input placeholder="Informe o Local Incoterms" v-model="ShippingMethod.IncotermsLocation"></el-input>
            </base-input>
          </div>
          -->
        </card>
      </div>

      <div class="col-lg-12 col-sm-12">
        <UpdateLog :dataLog="dataLog" />
      </div>
      <div class="col-12">
        <div class="
            d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
          <div></div>
          <div>
            <button class="custom-btn-save" @click="Save()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import planMixin from "src/mixins/planMixin.js";
import FileUpload from 'vue-upload-component'
import swal from "sweetalert2";
import { BasePagination } from "src/components";
import { BaseRadio } from "src/components/index";
import { mapState } from 'vuex';
import UpdateLog from "../../components/UpdateLog.vue";
import constants from '@/util/constants';

export default {
  mixins: [planMixin],
  components: {
    FileUpload,
    BaseRadio,
    BasePagination,
    UpdateLog
  },
  computed: {
    ...mapState(['realm', 'ecommerce']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      dataLog: [],
      files: [],
      addFile: true,
      templateImport: require('@/assets/template-xls/Template-Formas-Envio.xlsx'),
      showDetails: false,
      IcotermsS4Hana: constants.IcotermsS4Hana,
      ShippingMethod: {
        Id: 0,
        EcommerceName: null,
        Name: null,
        EcommerceReference: null,
        ErpReference: null,
        ErpReferenceCode: null,
        CarrierCode: null,
        HubReference: null,
        IdIncoterms: null,
        IncotermsLocation: null,
      },
      icotermOptions: [
        { value: 0, label: 'Contratação do Frete por conta do Remetente (CIF)' },
        { value: 1, label: 'Contratação do Frete por conta do Destinatário (FOB)' },
        { value: 2, label: 'Contratação do Frete por conta de Terceiros' },
        { value: 3, label: 'Transporte Próprio por conta do Remetente' },
        { value: 4, label: 'Transporte Próprio por conta do Destinatário' },
        { value: 9, label: 'Sem Ocorrência de Transporte' },
      ],
      erpTable: [],
      toInsert: true,
      Filters: {
        type: null,
        name: null,
        typeOptions: [
          { name: "Filtrar por...", value: null },
          { name: "Nome", value: "Name" },
          { name: "Referência no E-commerce", value: "EcommerceReference" },
          { name: "Referência no ERP", value: "ErpReference" },
          { name: "Cód. Transportadora", value: "CarrierCode" },
          { name: "ID Incoterms", value: "IdIncoterms" },
        ],
      },
      tableColumns: [
        {
          prop: "Name",
          label: "Nome",
          minWidth: 250,
        },
        {
          prop: "EcommerceReference",
          label: "Referência no E-commerce",
          minWidth: 100,
        },
        {
          prop: "ErpReference",
          label: "Referência no ERP",
          minWidth: 120,
        },
        {
          prop: "CarrierCode",
          label: "Cód. Transportadora",
          minWidth: 120,
        },
        {
          prop: "IdIncoterms",
          label: "ID Incoterms",
          minWidth: 120,
        },
      ],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      tableData: [],
      searchedData: [],

      showSpinner: false,
      response: null,
      enableErpReferenceManual: false,

      erpPlatformType: "",
      isSapBusinessOne: false,
      enableSAP4HANA: false,
    };
  },
  watch: {
    'ShippingMethod.ErpReference': function (name) {
      if (name) {
        this.ShippingMethod.ErpReferenceCode = this.erpTable.find(x => x.Name === name).Code;
      }
    }
  },
  mounted: async function () {
    this.erpPlatformType = "";
    this.enableSAP4HANA = false;

    await this.GetErpPlatformType();
    this.EnableErpReferenceManual();
    await this.GetShippingMethods();
    await this.GetErpPlatform();
  },
  methods: {
    GetErpPlatform() {
      this.isSapBusinessOne = this.VerifyErpPlatform(this.ecommerce.Erp.Platform);
    },
    VerifyErpPlatform(key) {
      return ["Sap Hana", "Sap SQL", "Sap XML"].includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    },
    inputFilter(newFile, oldFile, prevent) {
      if (newFile && !oldFile) {
        if (!/\.(xlsx)$/i.test(newFile.name)) {
          return prevent()
        }
      }
    },
    inputFile: async function (newFile, oldFile) {
      if (newFile && !oldFile) {
        this.addFile = true;
      }
      if (newFile && oldFile) {
        let formData = new FormData();
        formData.append("file", this.$refs.upload.files[0].file);

        let config = {
          headers: {
            "Content-Type": "multipart/form-data"
          }
        };

        if (this.addFile) {
          this.addFile = false;
          await this.$bahngleis.post(
            `ShippingMethod/Import?ecommerceName=${this.ecommerce.Name}`,
            formData, config
          ).then(async response => {
            if (response.status == 200) {
              if (response.headers["content-type"] == 'text/plain') {
                swal({
                  title: "Atenção",
                  html: `Ocorreu um erro ao importar o arquivo.<br> <b>Motivo: dados inseridos de maneira incorreta.</b>`,
                  type: "error",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false
                });
                this.downloadPlanErrors(response.data);
              } else {
                swal({
                  title: "Sucesso",
                  text: "O arquivo foi importado corretamente",
                  type: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false
                });
                this.addFile = true;
                this.$refs.upload.files[0].file.success = true;
              }
            } else {
              swal({
                title: "Atenção",
                text: "Ocorreu um erro ao importar o arquivo. " + response.message,
                type: "error",
                confirmButtonClass: "btn btn-success btn-fill",
                buttonsStyling: false
              });
            }
          }).catch(err => {
            swal({
              title: "Atenção",
              html: `Ocorreu um erro ao importar o arquivo.<br> <b>Motivo: ${err.message}.</b>`,
              type: "error",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false
            });
          });
        }
      }
      if (!newFile && oldFile) {
        // remove
      }
    },
    downloadPlanErrors(fileContent) {
      const url = window.URL.createObjectURL(new Blob([fileContent]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "erros.txt");
      document.body.appendChild(link);
      link.click();
    },
    GetShippingMethods: async function () {
      this.showSpinner = true;

      this.response = await this.$bahngleis.get(`/ShippingMethod/GetByEcommerce`, {
        params: {
          ecommerceName: this.ecommerce.Name
        },
      });

      this.tableData = this.response.data;
      this.Filters.name = null;
      this.Filters.type = null;
      this.showSpinner = false;
    },
    GetERPShippingMethods: async function () {
      this.showSpinner = true;

      this.response = await this.$bahngleis.get("SAP/shipping-methods", {
        params: {
          ecommerceName: this.ecommerce.Name
        },
      });

      this.erpTable = this.response.data;
      this.showSpinner = false;
    },
    GetErpPlatformType: async function () {
      this.showSpinner = true;
      this.erpPlatformType = this.GetErpPlatformTypeByKey(this.ecommerce.Erp.Platform);

      this.showSpinner = false;
    },
    GetErpPlatformTypeByKey(key) {
      return ("Sap Hana").includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    },
    ShowDetails: async function (shippingMethod) {
      this.ShippingMethod = shippingMethod;
      this.EnableErpReferenceManual();
      this.toInsert = false;
      this.showDetails = !this.showDetails;

      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        this.ShippingMethod.Id +
        "&entity=ShippingMethod"
      );

      this.dataLog = await responseLog.data;
    },
    HideDetails: function () {
      this.ShippingMethod = null;
      this.showDetails = !this.showDetails;
    },
    EnableErpReferenceManual() {
      if (this.erpPlatformType) {
        this.enableSAP4HANA = true;
        this.enableErpReferenceManual = false;
        this.GetERPShippingMethods();
      } else {
        this.enableSAP4HANA = false;
        this.enableErpReferenceManual = true;
      }
    },
    AddNew: function () {
      this.ShippingMethod = {
        ID: 0,
        Name: null,
        EcommerceReference: null,
        ErpReference: null,
        ErpReferenceCode: null,
        CarrierCode: null,
        IdIncoterms: null,
        IncotermsLocation: null
      };
      this.EnableErpReferenceManual();
      this.dataLog = [];
      this.toInsert = true;
      this.showDetails = !this.showDetails;
    },
    FilterShippingMethods: async function () {
      if (this.Filters.name == null || this.Filters.type == null) {
        this.ShowWarningNotification("Por favor, informe ambos os filtros");
      } else {
        this.showSpinner = true;

        this.response = await this.$bahngleis.get(`ShippingMethod/GetByEcommerce`, {
          params: {
            ecommerceName: this.ecommerce.Name
          },
        });
        this.tableData = await this.response.data;
        this.tableData = this.tableData.filter((item) => {
          switch (this.Filters.type) {
            case "Name":
              return (item.Name.toLowerCase().indexOf(this.Filters.name.toLowerCase()) > -1);
            case "EcommerceReference":
              return (item.EcommerceReference.toLowerCase().indexOf(this.Filters.name.toLowerCase()) > -1);
            case "ErpReference":
              return (item.ErpReference.toLowerCase().indexOf(this.Filters.name.toLowerCase()) > -1);
            case "CarrierCode":
              return (item.CarrierCode.toLowerCase().indexOf(this.Filters.name.toLowerCase()) > -1);
            case "IdIncoterms":
              return (item.IdIncoterms.toLowerCase().indexOf(this.Filters.name.toLowerCase()) > -1);
          }
        });
        this.showSpinner = false;
      }
    },
    Save: function () {
      if (!this.ShippingMethod.EcommerceReference) {
        this.ShowWarningNotification(
          "Por favor, informe a referência no <b>E-Commerce</b>"
        );
      } else if (!this.ShippingMethod.ErpReference) {
        this.ShowWarningNotification(
          "Por favor, informe a referência no <b>ERP</b>"
        );
      } else if (!this.ShippingMethod.Name) {
        this.ShowWarningNotification(
          "Por favor, informe o nome do <b>Método de Envio</b>"
        );
      } else if (!this.ShippingMethod.HubReference) {
        this.ShowWarningNotification(
          "Por favor, informe a referência no <b>HUB</b>"
        );
      }
      else {
        var vm = this;
        if (vm.toInsert) {
          vm.Insert();
        } else {
          vm.Update();
        }
      }
    },
    Insert: async function () {
      var vm = this;
      this.ShippingMethod.EcommerceName = this.ecommerce.Name;
      await this.$bahngleis
        .post(`ShippingMethod/`, this.ShippingMethod)
        .then(function () {
          swal({
            title: "Sucesso",
            text: "O método de envio foi inserido",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          vm.showDetails = false;
          vm.showSpinner = false;
          vm.GetShippingMethods();
        })
        .catch(function (error) {
          let message = error.response.data ? error.response.data : "Ocorreu um erro ao inserir o método de envio."
          swal({
            title: "Atenção",
            text: message,
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        });
    },
    Update: async function () {
      var vm = this;
      this.ShippingMethod.ecommerce = null;
      this.showSpinner = true;
      await this.$bahngleis
        .put(`ShippingMethod/${this.ShippingMethod.Id}`, this.ShippingMethod)
        .then(function () {
          swal({
            title: "Sucesso",
            text: "O método de envio foi atualizado",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          vm.showDetails = false;
          vm.GetShippingMethods();
          vm.showSpinner = false;
        })
        .catch(function (error) {
          let message = error.response.data ? error.response.data : "Ocorreu um erro ao atualizar o método de envio."
          swal({
            title: "Atenção",
            text: message,
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        });
    },
    Delete: async function (shippingMethod) {
      var vm = this;
      this.ShippingMethod = shippingMethod;

      swal({
        title: "Gostaria de excluir o método de envio?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((result) => {
        if (result.value) {
          var pos = this.tableData.indexOf(shippingMethod);
          this.tableData.splice(pos, 1);
          vm.Del();
        }
      });
    },

    Del: async function () {
      await this.$bahngleis
        .delete(`ShippingMethod/${this.ShippingMethod.Id}`)
        .then(function (response) {
          if (response.status === 200) {
            swal({
              title: "Sucesso",
              text: "O metodo envio foi excluido com sucesso",
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
            this.showDetails = false;
          } else {
            swal({
              title: "Atenção",
              text: "Ocorreu um erro ao excluir o metodo envio",
              type: "error",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },
    ShowWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      });
      return;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
  {
  opacity: 0;
}
</style>
