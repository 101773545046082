<template>
  <div class="flex-fill card-body">
    <div class="d-flex justify-content-end">
      <ProductAddKit @evtAddSku="addSku" />
    </div>
    <el-table :data="skus" style="width: 100%">
      <el-table-column prop="id" label="ID" width="100px"> </el-table-column>
      <el-table-column prop="skuSap" label="SKU SAP" width="auto">
        <template slot-scope="scope">
          <base-input>
            <el-input style="text-align:center" v-model="scope.row.skuSap"></el-input>
          </base-input>
        </template>
      </el-table-column>
      <el-table-column prop="name" label="Nome" width="auto">
        <template slot-scope="scope">
          <base-input>
            <el-input style="text-align:center" v-model="scope.row.name"></el-input>
          </base-input>
        </template>
      </el-table-column>
      <el-table-column prop="quantity" label="Quantidade" width="150px">
        <template slot-scope="scope">
          <base-input>
            <el-input type="number" min="0" style="text-align:center" v-model="scope.row.quantity"></el-input>
          </base-input>
        </template>
      </el-table-column>
      <el-table-column v-if="showAlocationPercentage" prop="allocationPercentage" label="% de Rateio" width="150px">
        <template slot-scope="scope">
          <base-input>
            <el-input type="number" min="0" style="text-align:center" v-model="scope.row.allocationPercentage"></el-input>
          </base-input>
        </template>
      </el-table-column>
      <el-table-column prop="Active" label="Status">
        <template slot-scope="scope">
          <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
            v-model="scope.row.active" :sync="true" />
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>
<script>
import ProductAddKit from "@/pages/Product/components/ProductAddKit";
import constants from '../../../util/constants';
import validateMixin from "@/mixins/validateMixin";
import { EventBus } from "@/eventBus";
import { mapActions, mapState, mapMutations } from "vuex";

export default {
  mixins: [validateMixin],
  components: {
    ProductAddKit,
  },
  data() {
    return {
      skus: [],
      dialogVisible: false,
      formChange: false,
      renderedComponent: false,
      showAlocationPercentage: true
    };
  },
  computed: {
    ...mapState("product", ["productCurrent", "formChanged"]),
    ...mapState(['ecommerce']),
  },
  methods: {
    ...mapActions("product", ["deleteSku"]),
    ...mapMutations('product', ['SET_FORM_CHANGED']),
    ...mapActions("configurations", ["getConfigurationByKeys"]),
    addSku(sku) {
      this.skus.push(sku);
    },
    reIndexSkus() {
      this.skus.forEach((attr, index) => {
        attr.index = index + 1;
      });
    },
    value() {
      return { skus: [...this.skus] };
    },
    async fillForm() {
      await this.GetConfigurations();
      if (this.productCurrent && this.productCurrent.Skus) {
        this.skus = this.productCurrent.Skus
          .filter((a) => a.IsKit)
          .map((a) => {
            return {
              id: a.Id,
              name: a.Name,
              active: a.Active,
              skuSap: a.SkuSAP,
              quantity: a.Quantity,
              allocationPercentage: a.AllocationPercentage,
              isKit: a.IsKit,
              imagens: a.Imagens.map((i) => {
                return { id: i.Id, url: i.Url };
              }),
              specifications: a.Specifications.map((s) => {
                return { id: s.Id, attributeId: s.AttributeId, value: s.Value };
              }),
            };
          });
        this.reIndexSkus();
      }
    },
    checkTypeAllocationKit(typeAllocationKit) {
      this.showAlocationPercentage = typeAllocationKit !== null && typeAllocationKit.Value === 'AllocationByPercentage' ? true : false;
    },
    GetConfigurations: async function () {
      let query = {
        ecommerceName: this.ecommerce.Name,
        keys: [constants.Configurations.TypeAllocationKit]
      };

      await this.getConfigurationByKeys(query).then(response => {
        this.checkTypeAllocationKit(response.data.find(a => a.Key === constants.Configurations.TypeAllocationKit));
      });
    }
  },
  created() {
    EventBus.$on("ProductRegisterEdition", () => this.fillForm());
  },
  watch: {
    skus: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.formChange == false && this.renderedComponent) {
          this.formChange = true;
          this.SET_FORM_CHANGED(true);
        }
      }
    }
  },
  mounted() {
    setTimeout(() => {
      this.renderedComponent = true;
    }, 3000);
  },
};
</script>
<style scoped>
.card-body {
  margin: 10px;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
}
</style>
