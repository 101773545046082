<template>
  <div>
    <el-tabs>
      <el-tab-pane>
        <span slot="label"><i class="tim-icons icon-vector"></i> Ecommerce e ERP</span>
        <PaymentMethodEcommerce ref="ecommerce" />
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-money"></i> Contas a Receber</span>
        <PaymentMethodIncomingPayment ref="incomingPayment" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import PaymentMethodIncomingPayment from "./PaymentMethodIncomingPayment.vue";
import PaymentMethodEcommerce from "./PaymentMethodEcommerce.vue";
import { mapState } from "vuex";
import constants from '@/util/constants';


export default {
  components: {
    PaymentMethodIncomingPayment,
    PaymentMethodEcommerce
  },
  data() {
    return {
      //isSAPHANA: true,
    }
  },
  computed: {
    ...mapState(['ecommerce'])
  },
  methods: {
    isFormValid() {
      if (Object.values(this.$refs).some((ref) => !ref.validate()))
        return false;
      else return true;
    },
    prepareFormToSend() {
      let payload = {};
      Object.values(this.$refs).forEach((ref) =>
        Object.assign(payload, ref.value())
      );

      return payload;
    },
    // GetErpPlatformTypeByKey(key) {
    //   return ("Sap Hana").includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    // },
    // async GetErpPlatformType() {
    //   this.isSAPHANA = this.GetErpPlatformTypeByKey(this.ecommerce.Erp.Platform);
    // }
  },
  mounted() {
    //this.GetErpPlatformType();
  },
};
</script>
<style>
.el-tabs__item {
  color: #eff6f7 !important;
}

.el-tabs__item.is-active {
  color: #00adb7 !important;
}

.el-tabs__item:hover {
  color: #00adb7 !important;
}

.el-tabs__active-bar {
  background-color: #00adb7 !important;
}
</style>
