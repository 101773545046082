<template>
  <card card-body-classes="table-full-width">
    <h4 slot="header" id="v-step-19" class="card-title">Depósitos</h4>
    <div>
      <div v-if="checkFunctionality">
        <div class="row" style="float: right">
          <span class="mr-2">
            <base-input>
              <el-input type="search" prefix-icon="el-icon-search" placeholder="Depósito no E-commerce"
                v-model="filters.warehouseEcommerce" style="width: 217px;"/>
            </base-input>
          </span>
          <span class="mr-2">
            <base-input>
              <el-input type="search" prefix-icon="el-icon-search" placeholder="Depósito SAP"
                v-model="filters.warehouseCode" />
            </base-input>
          </span>
          <span class="mr-2">
            <base-input>
              <el-input type="search" prefix-icon="el-icon-search" placeholder="Filial SAP" v-model="filters.filial" />
            </base-input>
          </span>
          <span class="mr-2">
            <base-input>
              <el-select class="select-primary mb-3 pagination-select" v-model="filters.active">
                <el-option class="select-primary" v-for="item in statusOptions" :key="item.name" :label="item.name"
                  :value="item.value"></el-option>
              </el-select>
            </base-input>
          </span>
          <span class="mr-2">
            <base-input v-if="filters.type == 'TaxOperationType'">
              <el-select class="select-primary mb-3 pagination-select" v-model="filters.name">
                <el-option class="select-primary" v-for="item in filters.typeOptions" :key="item.name" :label="item.Label"
                  :value="item.Key"></el-option>
              </el-select>
            </base-input>
          </span>
          <span class="mr-2">
            <button class="custom-btn-filter" @click="onLoad">
              Filtrar
            </button>
          </span>
          <span class="mr-2">
            <button class="custom-btn-filter" @click="clearFilter">
              Limpar filtro
            </button>
          </span>
          <span class="mr-2">
            <button class="custom-btn-add" @click="addNew()" v-if="getUserPermission('44_c')">
              Novo
            </button>
          </span>
        </div>
      </div>
      <el-table :data="queriedData" scope="scope">
        <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
          :prop="column.prop" :label="column.label" :formatter="column.formatter" align="center">
        </el-table-column>

        <el-table-column label="Filial SAP" align="center" min-width="150em">
          <div slot-scope="props">
            {{ props.row.Filial }}
          </div>
        </el-table-column>

        <el-table-column label="Depósito Transferência" align="center" min-width="150em">
          <div slot-scope="props">
            {{ props.row.WarehouseTransfer }}
          </div>
        </el-table-column>
        <el-table-column label="Depósito NF de Entrada" align="center" min-width="150em">
          <div slot-scope="props">
            {{ props.row.WarehousePurchaseInvoice }}
          </div>
        </el-table-column>

        <el-table-column label="Ativo" align="center" min-width="150em">
          <div slot-scope="props">
            <i v-if="props.row.Active" class="far fa-check-circle green-icon"></i>
            <i v-else class="far fa-times-circle red-icon"></i>
          </div>
        </el-table-column>

        <el-table-column label="Picking" align="center" min-width="150em">
          <div slot-scope="props">
            <i v-if="props.row.Picking" class="far fa-check-circle green-icon"></i>
            <i v-else class="far fa-times-circle red-icon"></i>
          </div>
        </el-table-column>

        <el-table-column label="Integração" align="center" min-width="150em">
          <div slot-scope="props">
            <i v-if="props.row.IsIntegration" class="far fa-check-circle green-icon"></i>
            <i v-else class="far fa-times-circle red-icon"></i>
          </div>
        </el-table-column>

        <el-table-column label="Consulta" align="center" min-width="150em">
          <div slot-scope="props">
            <i v-if="props.row.ReadOnly" class="far fa-check-circle green-icon"></i>
            <i v-else class="far fa-times-circle red-icon"></i>
          </div>
        </el-table-column>

        <el-table-column label="B2B" align="center" min-width="150em">
          <div slot-scope="props">
            <i v-if="props.row.IsB2B" class="far fa-check-circle green-icon"></i>
            <i v-else class="far fa-times-circle red-icon"></i>
          </div>
        </el-table-column>

        <el-table-column label="Ignorar saldo de Estoque?" align="center" min-width="150em">
          <div slot-scope="props">
            <i v-if="props.row.IgnoreInventoryBalance" class="far fa-check-circle green-icon"></i>
            <i v-else class="far fa-times-circle red-icon"></i>
          </div>
        </el-table-column>

        <el-table-column :min-width="'100px'" align="right" label="Ações">
          <div slot-scope="props">
            <base-button class="like btn-link" type="primary" size="sm" icon @click="edit(props.row)"
              v-if="getUserPermission('44_c')">
              <i class="material-icons">mode_edit_outline</i>
            </base-button>
            <base-button class="like btn-link" type="danger" size="sm" icon @click="remove(props.row)"
              v-if="getUserPermission('44_c')">
              <i class="material-icons">delete_outline</i>
            </base-button>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div slot="footer" class="
        col-12
        d-flex
        justify-content-center justify-content-between
        flex-wrap
      ">
      <div class="col-5"></div>
      <div class="col-2">
        <label>
          Exibindo
          <span class="primary-text">{{ from + 1 }}</span> -
          <span class="primary-text">{{ to }}</span> de
          <span class="primary-text">{{ total }}</span> registros
        </label>
      </div>
      <div class="col-3">
        <base-pagination class="pagination-no-border" v-model="pagination.currentPage" :per-page="pagination.perPage"
          :total="total"></base-pagination>
      </div>
      <div class="col-2">
        <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
          v-model="pagination.perPage" placeholder="Per page">
          <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
            :value="item"></el-option>
        </el-select>
      </div>
    </div>
  </card>
</template>

<script>
import planMixin from "src/mixins/planMixin.js";
import { mapState } from "vuex";
import constants from '@/util/constants';
import {
  //   BaseProgress,
  BaseSwitch,
  //   Slider,
  TagsInput
} from "src/components/index";
import { BasePagination } from "src/components";
import swal from "sweetalert2";

export default {
  mixins: [planMixin],
  components: {
    BasePagination
  },
  computed: {
    ...mapState(['ecommerce']),
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    }
  },
  data() {
    return {
      currentEcommerce: undefined,
      //isSAPHANA: false,
      filters: {
        type: null,
        name: null,
        warehouseEcommerce: null,
        warehouseCode: null,
        filial: null,
        active: null
      },
      statusOptions: [
        { name: "Status", value: null },
        { name: "Ativo", value: "true" },
        { name: "Inativo", value: "false" },
      ],
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0
      },
      searchQuery: "",
      propsToSearch: ["name"],

      tableColumns: [
        {
          prop: "WarehouseEcommerce",
          label: "Depósito e-commerce",
          minWidth: "150em"
        },
        {
          prop: "Code",
          label: "Depósito SAP",
          minWidth: "150em"
        }
      ],
      tableData: [],
      searchedData: [],

      checkFunctionality: true
    };
  },
  methods: {
    addNew: function () {
      this.$router.push({ name: "WarehouseConfigurationNew" });
    },
    async clearFilter() {
      this.filters.warehouseCode = null;
      this.filters.warehouseEcommerce = null;
      this.filters.filial = null;
      this.filters.active = null;
      this.filters.active = null;
      await this.onLoad();
    },
    onLoad: async function () {
      let response = await this.$bahngleis.get("configuration/warehouse", {
        params: {
          ecommerceName: this.currentEcommerce,
          warehouseCode: this.filters.warehouseCode,
          warehouseEcommerce: this.filters.warehouseEcommerce,
          filial: this.filters.filial,
          active: this.filters.active
        }
      });
      this.tableData = response.data;
    },
    edit: async function (row) {
      this.$router.push({
        name: "WarehouseConfigurationEdit",
        params: { id: row.Id }
      });
    },
    remove: async function (row) {
      let vm = this;
      swal({
        title: "Gostaria de excluir o depósito?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: 'Sim'
      }).then(async result => {
        if (result.value === true) {
          await this.$bahngleis
            .delete("configuration/warehouse/" + row.Id)
            .then(function (response) {
              if (response.status === 200) {
                swal({
                  title: "Sucesso",
                  text: "O depósito foi excluido com sucesso",
                  type: "success",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false
                });
                vm.showDetails = false;
                vm.onLoad();
              }
              else {
                swal({
                  title: "Atenção",
                  text: "Ocorreu um erro ao excluir o depósito",
                  type: "error",
                  confirmButtonClass: "btn btn-success btn-fill",
                  buttonsStyling: false
                });
              }
            })
        }
      });
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    },
    // GetErpPlatformTypeByKey(key) {
    //   return ("Sap Hana").includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    // },
    // async GetErpPlatformType() {
    //   this.isSAPHANA = this.GetErpPlatformTypeByKey(this.ecommerce.Erp.Platform);
    // }
  },
  mounted: async function () {
    this.currentEcommerce = this.$store.state.ecommerce.Name;
    //await this.GetErpPlatformType();
    await this.onLoad();
    // await this.getPlan();
    // await this.getFunctionalities(this.isPlanControlActive);
  }
};
</script>

<style>
.green-icon {
  color: rgb(35, 207, 35);
}

.red-icon {
  color: rgb(241, 15, 15);
}
</style>
