<template>
    <el-dialog
        :title="getTitle()"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <span>Intervalo de execução</span>
        <el-select class="select-primary ml-3" v-model="recurringJob.Cron">
            <el-option
            v-for="item in cronOptions"
            :key="item.Key"
            :label="item.Label"
            :value="item.Key">
            </el-option>
        </el-select>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">Cancelar</el-button>
            <el-button type="primary" @click="save">Atualizar</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Modal from '@/components/Modal'
import constants from '@/util/constants'
import { mapState } from 'vuex';
export default {
    components: [
        Modal
    ],
    components: { Modal },
    data() {
        return {
            dialogVisible: false,
            number : null,
            skus : [],
            recurringJob : null,
            title : "",
            cronOptions : []
        }
    },
    computed : {
        ...mapState('configurations',['scheduleCurrent'])
    },
    methods: {
        handleClose(){
            this.$router.push({name : 'Schedules'});
        },
        async save(){
            await this.$bahngleis.patch("RecurringJobs/update", this.recurringJob);

            this.$showSuccess("Atualização realizada com sucesso!");

            this.handleClose();
        },
        getTitle(){
            return "Detalhe - " + this.scheduleCurrent.Name;
        }
    },
    beforeMount() {
        this.recurringJob =  this.scheduleCurrent;
        this.cronOptions = constants.CronOptions;
        this.dialogVisible = true;
    },
    destroyed() {
        this.dialogVisible = false;
    },
}
</script>