<template>
  <transition name="fade" mode="out-in">
    <div v-if="!showDetails">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Endereços</h4>
        <div>
          <div class="row" style="float: right">
            <span style="margin: 0 10pt">
              <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome" v-model="Filters.name"
                  @keyup.enter="Filter"></el-input>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <base-input>
                <el-select class="select-primary mb-3 pagination-select" v-model="Filters.type" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in Filters.typeOptions" :key="item.name"
                    :label="item.name" :value="item.value"></el-option>
                </el-select>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="Filter()">
                Filtrar
              </button>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="ClearFilter()">
                Limpar filtro
              </button>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-add" @click="AddNew()" v-if="getUserPermission('06_c')">Novo</button>
            </span>
          </div>
          <svg v-if="showSpinner" class="spinner-lg" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
          <el-table :data="tableData">
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
              :prop="column.prop" :label="column.label" align="center"></el-table-column>
            <el-table-column :min-width="135" align="right" label="Ações">
              <div slot-scope="props">
                <base-button class="like !important btn-link" type="primary" size="sm" icon
                  @click="ShowDetails(props.row)" v-if="getUserPermission('06_c')">
                  <i class="material-icons">mode_edit_outline</i>
                </base-button>
                <base-button class="edit btn-link" type="danger" size="sm" icon @click="Delete(props.row)"
                  v-if="getUserPermission('06_c')">
                  <i class="material-icons">delete_outline</i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="col-12 d-flex justify-content-center justify-content-between flex-wrap">
          <div class="col-5"></div>
          <div class="col-2">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>
          <div class="col-3">
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
              :per-page="pagination.perPage" :total="total" @input="Filter"></base-pagination>
          </div>
          <div class="col-2">
            <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
              v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </card>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <h4 slot="header" class="card-title">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="HideDetails()">
            <i class="material-icons">arrow_back</i> </base-button>Cadastro do De / Para
        </h4>
      </div>
      <div class="col-sm-4">
        <card type="tasks" class="stacked-form">
          <base-input class="col-sm-12 table-full-width table-responsive" label="AbsId" placeholder="Informar o AbsId"
            :required="true" type="number" min="1" v-model="County.AbsId" />

          <base-input class="col-sm-12 table-full-width table-responsive" label="Código" placeholder="Informar o Código"
            :required="true" type="number" min="0" v-model="County.Code" />

          <base-input class="col-sm-12 table-full-width table-responsive" label="País" placeholder="Informar o País"
            :required="true" v-model="County.Country" />

          <base-input class="col-sm-12 table-full-width table-responsive" label="Estado" placeholder="Informar o Estado"
            :required="true" v-model="County.State" />

          <base-input class="col-sm-12 table-full-width table-responsive" label="Município"
            placeholder="Informar o nome do Município" :required="true" v-model="County.Name" />

          <base-input class="col-sm-12 table-full-width table-responsive" label="Zona Franca">
            <el-select class="select-primary" v-model="County.TaxZone">
              <el-option v-for="option in CustomFieldTaxZone" :value="option.Key" :label="option.Label"
                :key="option.Key"></el-option>
            </el-select>
          </base-input>

          <base-input class="col-sm-12 table-full-width table-responsive" label="Código IBGE"
            placeholder="Informar o código do Município" :required="true" type="number" min="0"
            v-model="County.IbgeCode" />
        </card>
      </div>
      <div class="col-lg-12 col-sm-12">
        <UpdateLog :dataLog="dataLog" />
      </div>
      <div class="col-12">
        <div class="d-flex justify-content-center justify-content-between flex-wrap">
          <div></div>
          <div>
            <button class="custom-btn-save" @click="Save()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import swal from "sweetalert2";
import { BasePagination, BaseRadio } from "src/components";
import constants from '@/util/constants'
import UpdateLog from "../../components/UpdateLog.vue";

export default {
  components: {
    BaseRadio,
    BasePagination,
    UpdateLog
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    }
  },
  data() {
    return {
      dataLog: [],
      showDetails: false,
      ecommerce: "",
      selectedEcomm: "",
      toDisabled: "0",

      Filters: {
        type: null,
        name: null,
        typeOptions: [
          { name: "Filtrar por...", value: null },
          { name: "Abs Id", value: "AbsId" },
          { name: "País", value: "Country" },
          { name: "Estado", value: "State" },
          { name: "Município", value: "Name" },
          { name: "Ibge Code", value: "IbgeCode" }
        ],
      },

      tableColumns: [
        {
          prop: "Id",
          label: "Id",
          minWidth: 100,
        },
        {
          prop: "AbsId",
          label: "Abs Id",
          minWidth: 100,
        },
        {
          prop: "Country",
          label: "País",
          minWidth: 100,
        },
        {
          prop: "State",
          label: "Estado",
          minWidth: 100,
        },
        {
          prop: "Name",
          label: "Município",
          minWidth: 100,
        },
        {
          prop: "IbgeCode",
          label: "Ibge Code",
          minWidth: 100,
        },
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      tableData: [],

      showSpinner: false,
      response: null,

      County: null,

      erpTable: [],
      paymentConditionTable: [],
      CustomFieldTaxZone: [],

      toInsert: true,
      showSpinner1: false,
      showSpinner2: false,
    };
  },
  mounted() {
    this.selectedEcomm = this.$store.state.ecommerce.Name;
    this.CustomFieldTaxZone = constants.CustomFieldTaxZone;
    this.Filter();
  },
  methods: {
    //---- Actions ---------------------------------------------------------
    Filter: async function () {
      if ((this.Filters.name != null && this.Filters.name != '') && this.Filters.type === null) {
        this.ShowWarningNotification("Por favor, informe ambos os filtros");
      } else {
        this.showSpinner = true;
        let filter = this.Filters.type && this.Filters.name
          ? `${this.Filters.type}=${this.Filters.name}` : '';

        await this.$bahngleis.get(
          `County/GetByFilter?${filter}`
          + `&CurrentPage=${this.pagination.currentPage}&PerPage=${this.pagination.perPage}`)
          .then(response => {
            this.tableData = response.data.Data;
            this.showSpinner = false;
            this.pagination.total = response.data.Total;
          });
      }
    },

    ClearFilter: async function () {
      this.Filters.type = null;
      this.Filters.name = null;
      await this.Filter();
    },

    ShowDetails: async function (county) {
      this.County = county;
      this.toInsert = false;
      this.toDisabled = "1";
      this.showDetails = !this.showDetails;

      let responseLog = await this.$bahngleis.get(
        `/LogAction/getlogsofentity?identity=${this.County.Id}&entity=County`);

      this.dataLog = await responseLog.data;
    },

    AddNew: function () {
      this.County = {
        Id: 0,
        AbsId: null,
        Code: null,
        County: null,
        State: null,
        Name: null,
        TaxZone: "N",
        IbgeCode: null
      };
      this.addLog = [];
      this.toInsert = true;
      this.toDisabled = "0";
      this.showDetails = !this.showDetails;
    },

    Delete: function (county) {
      var vm = this;
      this.County = county;

      swal({
        title: "Gostaria de excluir o registro?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((result) => {
        if (result.value) {
          var pos = this.tableData.indexOf(county);
          this.tableData.splice(pos, 1);
          vm.Del();
        }
      });
    },

    HideDetails: function () {
      this.County = null;

      this.showDetails = !this.showDetails;
    },
    Save: function () {
      if (!this.County.AbsId) {
        this.ShowWarningNotification("Por favor, informe o <b>AbsID</b>");
      } else if (!this.County.Code) {
        this.ShowWarningNotification("Por favor, informe o <b>Código</b>");
      } else if (!this.County.Country) {
        this.ShowWarningNotification("Por favor, informe o <b>País</b>");
      } else if (!this.County.State) {
        this.ShowWarningNotification("Por favor, informe o <b>Estado</b>");
      } else if (!this.County.Name) {
        this.ShowWarningNotification("Por favor, informe o <b>Município</b>");
      } else if (!this.County.IbgeCode) {
        this.ShowWarningNotification("Por favor, informe o <b>Código do Município</b>");
      } else {
        this.County.State = this.County.State.toUpperCase();
        this.County.Country = this.County.Country.toUpperCase();
        var vm = this;
        if (vm.toInsert) {
          vm.Insert();
        } else {
          vm.Update();
        }
      }
    },
    Insert: function () {
      this.County.ecommerceName = this.selectedEcomm;
      var vm = this;
      this.$bahngleis
        .post("County", this.County, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function () {
          swal({
            title: "Sucesso",
            text: "O cadastro foi inserido com sucesso",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          vm.showDetails = false;
        })
        .catch(function () {
          swal({
            title: "Atenção",
            text: "Ocorreu um erro ao inserir o cadastro",
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        });
    },
    Del: function () {
      this.County.ecommerceName = this.selectedEcomm;

      this.$bahngleis
        .delete(`County/${this.County.Id}`)
        .then(function (response) {
          if (response.status === 200) {
            swal({
              title: "Sucesso",
              text: "O cadastro foi excluido com sucesso",
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
            vm.showDetails = false;
          } else {
            swal({
              title: "Atenção",
              text: "Ocorreu um erro ao excluir o cadastro",
              type: "error",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },

    Update: function () {
      var vm = this;
      this.$bahngleis
        .put(`County/${this.County.Id}`, this.County, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .then(function () {
          swal({
            title: "Sucesso",
            text: "O cadastro foi atualizado",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          vm.showDetails = false;
        })
        .catch(function () {
          swal({
            title: "Atenção",
            text: "Ocorreu um erro ao atualizar o cadastro",
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        });
    },

    //---- Auxiliaries ---------------------------------------------------------
    ShowWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      });
      return;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
  {
  opacity: 0;
}
</style>
