<template>
  <div>
    <div class="col-12">
      <h4 slot="header" class="card-title">
        <base-button class="like !important btn-link" type="primary" size="sm" icon
          @click="$router.push({ name: 'Ecommerce' })">
          <i class="material-icons">arrow_back</i>
        </base-button>
        Criar Novo E-commerce
      </h4>
    </div>
    <div class="col-md-12">
      <card type="tasks">
        <h4 class="card-title">Nome do e-commerce</h4>
        <div class="form-group table-full-width table-responsive">
          <base-input class="col-md-4">
            <el-input placeholder="Nome do e-commerce" v-model="newEcommerce.name"></el-input>
          </base-input>
        </div>
        <h4 class="card-title">URL</h4>
        <div class="form-group table-full-width table-responsive">
          <base-input class="col-md-4">
            <el-input placeholder="url" v-model="newEcommerce.url" @input="isValidUrl($event)"
              @change="isValidUrl($event)"></el-input>
          </base-input>
          <div class="validate-url" v-if="!ValidUrl">URL inválida</div>
        </div>

        <h4 class="card-title">ERP</h4>
        <div class="form-group table-full-width table-responsive">
          <div class="col-md-4">
            <el-select class="select-primary" v-model="newEcommerce.Erp.Platform">
              <el-option :name="option.label" v-for="option in erpList" :value="option.value" :label="option.label"
                :key="option.label"></el-option>
            </el-select>
          </div>
        </div>
        <h4 class="card-title">Plataforma</h4>
        <div class="form-group table-full-width table-responsive">
          <div class="col-md-4">
            <el-select class="select-primary" v-model="newEcommerce.platform">
              <el-option :name="option.label" v-for="option in platFormList" :value="option.value" :label="option.label"
                :key="option.label"></el-option>
            </el-select>
          </div>
        </div>
      </card>
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-center justify-content-between flex-wrap">
        <div></div>
        <div>
          <button class="custom-btn-save" @click="save">Salvar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      platFormList: this.$route.params.platFormList,
      erpList: this.$route.params.erpList,
      newEcommerce: {
        name: null,
        url: "https://",
        companyId: null,
        platform: null,
        Erp: {
          Platform: null
        },
        hubId: null,
        active: true,
      },
      ValidUrl: true,
      regexUrl:
        /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[\-;:&=\+\$,\w]+@)?[A-Za-z0-9\.\-]+|(?:www\.|[\-;:&=\+\$,\w]+@)[A-Za-z0-9\.\-]+)((?:\/[\+~%\/\.\w\-_]*)?\??(?:[\-\+=&;%@\.\w_]*)#?(?:[\.\!\/\\\w]*))?)/,
    }
  },
  computed: {
    ...mapState(['company']),
    ...mapState("ecommerceView", ["ecommerce"]),
  },
  methods: {
    async save() {
      try {
        if (!this.validate()) return;
        await this.$bahngleis.post(`/Ecommerce`, this.newEcommerce, {
          headers: {
            "Content-Type": "application/json",
          },
        }).then(async () => {
          const loading = this.$loading({
            lock: true,
            text: 'Criando Configurações..',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)'
          });
          loading.close();
          this.$showSuccess("Novo E-commerce inserido com sucesso.");
          this.$router.push({ name: "Ecommerce" });
        });
      } catch (error) {
        this.$showError(error);
      }
    },
    validate() {
      if (this.newEcommerce.name == null) {
        this.$showError("Por favor, informe o nome do <b>E-Commerce</b>");
        return false;
      } else if (this.newEcommerce.url == null) {
        this.$showError("Por favor, informe a URL do <b>E-Commerce</b>");
        return false;
      } else if (this.newEcommerce.platform == null) {
        this.$showError("Por favor, informe a plataforma do <b>E-Commerce</b>");
        return false;
      }

      return true;
    },
    isValidUrl(inputUrl) {
      this.ValidUrl = this.regexUrl.test(inputUrl);
    },
  },
  mounted() {
  }
};
</script>
