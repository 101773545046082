<template>
  <div>
    <button class="custom-btn-save m-1" @click="openModal">
      Adicionar
    </button>
    <el-dialog :visible.sync="dialogVisible" width="75%" :before-close="handleClose">
      <h4>Novo item no Kit</h4>
      <div class="d-flex">
        <base-input class="m-1" label="Nome" :required="true" v-model="sku.name" />
        <base-input class="m-1" label="SKU no SAP" :required="true" v-model="sku.skuSap" />
        <base-input class="m-1" label="Quantidade" :required="true" v-model="sku.quantity" type="number" />
        <base-input v-if="showAlocationPercentage" class="m-1" label="% de Rateio" v-model="sku.allocationPercentage"
          type="number" />
        <div class="flex-fill ml-3" align="left">
          <h5>Ativo</h5>
          <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
            v-model="sku.active" :sync="true" />
        </div>
      </div>
      <el-divider></el-divider>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">Cancelar</el-button>
        <el-button type="primary" @click="addSku">Adicionar</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";
import { EventBus } from "@/eventBus";
import constants from '../../../util/constants';

export default {
  data() {
    return {
      dialogVisible: false,
      sku: {
        name: null,
        skuSap: null,
        active: true,
        imagens: [],
        specifications: [],
        isKit: true,
        quantity: 0,
        allocationPercentage: 0
      },
      showAlocationPercentage: true
    };
  },
  computed: {
    ...mapState("product", ["productCurrent", "attributes", "scheduleSyncProductsActive"]),
    ...mapState(['ecommerce']),
  },
  methods: {
    ...mapActions("configurations", ["getConfigurationByKeys"]),
    remove(index) {
      this.sku.specifications = this.sku.specifications.filter(
        (attr) => attr.index != index
      );
      this.reIndexAttributes();
    },
    reIndexAttributes() {
      this.sku.specifications.forEach((attr, index) => {
        attr.index = index + 1;
      });
    },
    handleClose() {
      this.sku = {
        name: null,
        skuSap: null,
        active: true,
        isKit: true,
        imagens: [],
        specifications: [],
        quantity: 0,
        allocationPercentage: 0
      };

      this.dialogVisible = false;
      EventBus.$emit("clearTouched");
    },
    openModal() {
      this.dialogVisible = true;
    },
    checkTypeAllocationKit(typeAllocationKit) {
      this.showAlocationPercentage = typeAllocationKit !== null && typeAllocationKit.Value === 'AllocationByPercentage' ? true : false;
    },
    async getConfigurations() {
      let query = {
        ecommerceName: this.ecommerce.Name,
        keys: [constants.Configurations.TypeAllocationKit]
      };

      await this.getConfigurationByKeys(query).then(response => {
        this.checkTypeAllocationKit(response.data.find(a => a.Key === constants.Configurations.TypeAllocationKit));
      });
    },
    validate() {
      let errors = "";
      let valid = true;

      if (!this.sku.name) {
        errors += "- <b>Nome</b> obrigatório";
        valid = false;
      }
      if (!this.sku.skuSap) {
        errors += "<br>- <b>SKU no SAP</b> obrigatório";
        valid = false;
      }
      if (this.sku.quantity <= 0) {
        errors += "<br>- <b>Quantidade</b> obrigatório";
        valid = false;
      }
      if (this.showAlocationPercentage && this.sku.allocationPercentage <= 0) {
        errors += "<br>- <b>% de Rateio</b> obrigatório";
        valid = false;
      }

      valid = !errors.length > 0;

      if (!valid)
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          duration: 3000,
          message: errors
        });

      return valid;
    },
    addSku() {
      if (!this.validate())
        return;

      this.$emit("evtAddSku", this.sku);
      this.sku = {
        name: null,
        eCommerceId: null,
        ean: null,
        skuSap: null,
        active: true,
        imagens: [],
        specifications: []
      };

      this.handleClose();
    },
  },
  async mounted() {
    await this.getConfigurations();
    this.$validator.reset();
  }
};
</script>
<style>
.card-body {
  margin: 10px;
  border-width: 0.5px;
  border-radius: 5px;
}

.el-dialog {
  background: #181926;
}
</style>
