<template>
    <transition name="fade" mode="out-in">
        <card card-body-classes="table-full-width">
            <h4 slot="header" class="card-title">Histórico de Captação Manual</h4>
            <div class="row" style="float: right">
                <span style="margin: 0 10pt">
                    <base-input>
                        <el-input type="search" prefix-icon="el-icon-search" placeholder="N° do pedido"
                            v-model="filters.orderNumber" />
                    </base-input>
                </span>
                <span style="margin: 0 10pt">
                    <button class="custom-btn-filter" @click="load">
                        Filtrar
                    </button>
                </span>
                <span style="margin: 0 10pt">
                    <button class="custom-btn-filter" @click="clearFilter">
                        Limpar filtro
                    </button>
                </span>
            </div>
            <div>
                <el-table :data="logOrders.Items">
                    <el-table-column label="Captação" align="left" min-width="150">
                        <template slot-scope="props">
                            <div v-for="(item, index) in formatDescription(props.row.Description)" :key="index">
                                {{ item.field || '-' }}
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Pedido" align="center" min-width="100">
                        <template slot-scope="props">
                            <div v-for="(item, index) in formatDescription(props.row.Description)" :key="index">
                                <span v-if="isDate(item.newValue)">{{ formatDate(item.newValue) }}</span>
                                <span v-else>{{ item.newValue }}</span>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="User" label="Usuário" align="right"></el-table-column>
                    <el-table-column prop="Date" label="Data" :formatter="dateFormatter" align="right"></el-table-column>
                </el-table>
                <div slot="footer" class="col-12 d-flex justify-content-center justify-content-between flex-wrap">
                    <div class="col-5"></div>
                    <div class="col-2">
                        <label>
                            Exibindo
                            <span class="primary-text">{{ from + 1 }}</span> -
                            <span class="primary-text">{{ to }}</span> de
                            <span class="primary-text">{{ total }}</span> registros
                        </label>
                    </div>
                    <div class="col-3">
                        <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                            :per-page="pagination.perPage" :total="total" @input="load"></base-pagination>
                    </div>
                    <div class="col-2">
                        <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
                            v-model="pagination.perPage" placeholder="Per page">
                            <el-option class="select-primary" v-for="(item, index) in pagination.perPageOptions" :key="index"
                                :label="item" :value="item"></el-option>
                        </el-select>
                    </div>
                </div>
            </div>
        </card>
    </transition>
</template>
  
<script>
import { mapActions, mapState } from 'vuex';
import moment from "moment";
import { BasePagination, BaseRadio } from "src/components";

export default {
    components: {
        BaseRadio,
        BasePagination,
    },
    computed: {
        ...mapState("logOrder",["logOrders"]),
        queriedData() {
            let result = this.dataLog;
            return result.slice(this.from, this.to);
        },
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.logOrders.Total;
        },
    },
    data() {
        return {
            filters: {
                orderNumber: null
            },
            pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
            },
            object: {
                identity: this.$store.state.ecommerce.Name,
                entity: 'ManualCaptureOrder'
            },
        }
    },
    methods: {
        ...mapActions('logOrder', ['getLogActionPaginated']),
        async load() {
            let params = Object.assign(this.filters, this.object, this.pagination);
            await this.getLogActionPaginated(params);
        },
        clearFilter() {
            this.filters.orderNumber = null;
            this.load();
        },
        formatDescription(description) {
            let descriptionArray = []
            let obj = JSON.parse(description)

            for (const key in obj) {
                let newValue = obj[key].Item2;

                descriptionArray.push({
                    field: key,
                    newValue: newValue
                })
            }
            return descriptionArray
        },
        dateFormatter(row, col, value, index) {
            return moment.utc(value).format("DD/MM/YYYY HH:mm:ss");
        },
        isDate: function (dateStr) {
            const formats = [
                'YYYY-MM-DDTHH:mm:ss',
                'YYYY-MM-DDTHH:mm:ssZ'
            ];

            const momentDate = moment(dateStr, formats, true);
            return momentDate.isValid();
        },
        formatDate(value) {
            const momentDate = moment(value, moment.ISO_8601, true);
            if (momentDate.isValid()) {
                return momentDate.format("DD/MM/YYYY");
            } else {
                return value;
            }
        }
    },
    async mounted() {
        await this.load();
    }
}
</script>
  