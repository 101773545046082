<template>
    <el-dialog
        title="Captar Pedidos"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="handleClose">
        <div class="d-flex align-items-center ml-2">
            <el-checkbox v-model="showTextArea">Especificar pedido(s)?</el-checkbox>
        </div>
        <div v-if="showTextArea">
            <small style="color: gray">* Um pedido por linha</small>
            <div class="d-flex">
                <el-input placeholder="Pedido(s)" rows="5" type="textarea" v-model="number"></el-input>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">Cancelar</el-button>
            <el-button type="primary" @click="save">Captar Pedidos</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Modal from '@/components/Modal'

export default {
    components: [
        Modal
    ],
    components: { Modal },
    data() {
        return {
            dialogVisible: false,
            number : null,
            orderNumbers : [],
            showTextArea : false
        }
    },
    methods: {
        handleClose(){
            this.$router.go(-1);
        },
        clearNumber(){
            this.number = null;
        },
        save(){
            if(this.showTextArea && this.$isNullOrEmpty(this.number))
            {
                this.$showError("Nenhum pedido especificado!");
                return;
            }

            if(this.showTextArea)
                this.orderNumbers = this.number.split("\n");

            this.$bahngleis.post(`order/manual-order-capture?ecommerceName=${this.$store.state.ecommerce.Name}`, this.orderNumbers);

            this.$showSuccess("Captação de pedidos iniciada com sucesso!");

            this.handleClose();
        }
    },
    mounted() {
        this.clearNumber();
        this.dialogVisible = true;
    },
    destroyed() {
        this.dialogVisible = false;
    },
}
</script>
