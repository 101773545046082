<template>
  <transition name="fade" mode="out-in">
    <card card-body-classes="table-full-width">
      <h3 slot="header" class="card-title">Editar lista de preço</h3>
      <PriceListForm :typeForm="typeForm" ref="form" />
      <div slot="footer" class="col-12 d-flex justify-content-end flex-wrap">
        <button class="custom-btn-add mx-2" @click="$router.push({ name: 'PriceList' })">Voltar</button>
        <button v-if="getUserPermission('34_c')" class="custom-btn-save mx-2"
          @click="update">Atualizar</button>
      </div>
      <UpdateLog :dataLog="logs" />
    </card>
  </transition>
</template>
<script>
import PriceListForm from "../components/PriceListForm.vue";
import { mapActions, mapState, mapMutations } from "vuex";
import constants from "@/util/constants";
import { EventBus } from "@/eventBus";
import UpdateLog from "../../../components/UpdateLog.vue";

export default {
  components: {
    PriceListForm,
    UpdateLog
  },
  data() {
    return {
      typeForm: constants.typeForm.edition,
    };
  },
  computed: {
    ...mapState(['ecommerce']),
    ...mapState('priceList', ['priceLists', 'logs'])
  },
  methods: {
    ...mapActions("priceList", ["updatePriceList", "getPriceListLog"]),
    ...mapMutations('priceList', ['SET_PRICE_LIST_CURRENT']),
    async update() {
      let form = this.$refs.form;

      if (!form.validate())
        return;

      let payload = form.getValue();
      await this.updatePriceList(payload).then(() => {
        this.$showSuccess("Lista de preço atualizada com sucesso");

        this.$router.push({ name: "PriceList" });
      }).catch(error => {
        if (error.response.data)
          this.$showError(error.response.data);
        else
          this.$showError(error);
      });
    },
    getPriceListCurrent() {
      let priceListId = this.$route.params.id;
      let priceList = this.priceLists.find(a => a.Id === priceListId);
      this.SET_PRICE_LIST_CURRENT(priceList);
      this.getPriceListLog(priceListId);
      EventBus.$emit("loadPriceList");
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
  async mounted() {
    this.getPriceListCurrent();
  }
};
</script>
<style>
.el-dropdown-menu__item:focus,
.el-dropdown-menu__item:not(.is-disabled):hover {
  background-color: #00ADB7;
  color: #ffffff !important;
}

.el-button-group>.el-button.is-active,
.el-button-group>.el-button:active,
.el-button-group>.el-button:focus,
.el-button-group>.el-button:hover {
  color: #ffffff !important;
  background-color: #00ADB7 !important;
}

.el-button {
  display: inline-block;
  color: #ffffff;
  background-color: #00ADB7;
}
</style>
