<template>
    <transition name="fade" mode="out-in">
        <card card-body-classes="table-full-width">
            <h4 slot="header" id="v-step-18" class="card-title">Addons</h4>
            <!--
            <div class="row" style="float: right">
                <span style="margin: 0 10pt">
                    <el-button class="custom-btn-add" @click="addNew">Novo</el-button>
                </span>
            </div>
            -->
            <div>
                <el-table :data="addons">
                    <el-table-column label="Nome" align="center">
                        <div slot-scope="props">
                            {{ getAddonName(props.row.Name) }}
                        </div>
                    </el-table-column>
                    <el-table-column label="Status" align="center" min-width="25">
                        <div slot-scope="props">
                            <div v-bind:class="
                                CheckAddonStatus(props.row.Active)
                            ">
                                {{ props.row.Active == true ? "Ativo" : "Inativo" }}
                            </div>
                        </div>
                    </el-table-column>
                    <el-table-column align="center" label="Ações">
                        <div slot-scope="props">
                            <el-tooltip content="Editar" effect="light" :open-delay="300" placement="top">
                                <base-button class="like !important btn-link" type="primary" size="sm" icon
                                    @click="detail(props.row.Id)">
                                    <i class="material-icons">mode_edit_outline</i>
                                </base-button>
                            </el-tooltip>
                            <!--
                            <el-tooltip content="Deletar" effect="light" :open-delay="300" placement="top">
                                <base-button class="edit btn-link" type="danger" size="sm" icon
                                    @click="remove(props.row.Id)">
                                    <i class="material-icons">delete_outline</i>
                                </base-button>
                            </el-tooltip>
                            -->
                        </div>
                    </el-table-column>
                </el-table>
            </div>
            <div slot="footer" class="col-12 d-flex justify-content-center justify-content-between flex-wrap">
                <div class="col-5"></div>
                <div class="col-2">
                    <label>
                        Exibindo
                        <span class="primary-text">{{ from + 1 }}</span> -
                        <span class="primary-text">{{ to }}</span> de
                        <span class="primary-text">{{ total }}</span> registros
                    </label>
                </div>
                <div class="col-3">
                    <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
                        :per-page="pagination.perPage" :total="total" @input="loadAddons"></base-pagination>
                </div>
                <div class="col-2">
                    <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
                        v-model="pagination.perPage" placeholder="Per page">
                        <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item"
                            :label="item" :value="item"></el-option>
                    </el-select>
                </div>
            </div>
        </card>
    </transition>
</template>
<script>
import swal from "sweetalert2";
import constants from '../../../../util/constants';
import { mapActions, mapState } from 'vuex';
import { BasePagination, BaseRadio } from "src/components";

export default {
    components: {
        BaseRadio,
        BasePagination
    },
    data() {
        return {
            pagination: {
                perPage: 5,
                currentPage: 1,
                perPageOptions: [5, 10, 25, 50],
                total: 0,
                addonId: 0
            },
        };
    },
    computed: {
        ...mapState('addon', ['addons']),
        to() {
            let highBound = this.from + this.pagination.perPage;
            if (this.total < highBound) {
                highBound = this.total;
            }
            return highBound;
        },
        from() {
            return this.pagination.perPage * (this.pagination.currentPage - 1);
        },
        total() {
            return this.addons.Total;
        }
    },
    methods: {
        ...mapActions('addon', ['getAddons', 'removeAddon']),
        async loadAddons() {
            let params = Object.assign(this.pagination);
            await this.getAddons(params);
        },
        detail(id) {
            this.$router.push({ name: "AddonDetail", query: { id: id } })
        },
        addNew() {
            this.$router.push({ name: "AddonCreate" })
        },
        remove(id) {
            swal({
                title: "Gostaria de excluir o registro?",
                text: "Esse processo não poderá ser revertido.",
                showCancelButton: true,
                confirmButtonClass: "btn btn-success btn-fill",
                cancelButtonClass: "btn btn-danger btn-fill",
                cancelButtonText: "Não",
                confirmButtonText: "Sim",
            }).then(async (result) => {
                if (result.value) {
                    await this.delete(id)
                    await this.loadAddons();
                }
            });
        },
        async delete(id) {
            try {
                await this.removeAddon(id);
                this.$showSuccess("O cadastro foi excluido com sucesso");
            } catch (error) {
                this.$showError("Ocorreu um erro ao excluir o cadastro");
            }
        },
        getAddonName(key) {
            return constants.AddonName.find(a => a.value === key).label;
        },
        CheckAddonStatus: function (status) {
            const statusParams = {
                true: "statusActive",
                false: "statusInactive",
            };
            return `status ${statusParams[status]}` || "status statusDefault";
        },
    },
    async mounted() {
        await this.loadAddons();
    },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
    {
    opacity: 0;
}

textarea {
    background: transparent !important;
    border-color: #2b3553 !important;
}

.el-textarea__inner:focus {
    outline: 0;
    border-color: #00ADB7 !important;
}

.el-textarea__inner:hover {
    border-color: #00ADB7 !important;
}
</style>