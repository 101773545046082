<template>
  <transition name="fade" mode="out-in">
    <div v-if="!showDetails">
      <card card-body-classes="table-full-width">
        <h4 slot="header" class="card-title">Tipo de envio</h4>
        <div>
          <div class="row" style="float: right">
            <span style="margin: 0 10pt">
              <base-input>
                <el-input type="search" prefix-icon="el-icon-search" placeholder="Nome"
                  v-model="Filters.name"></el-input>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <base-input>
                <el-select class="select-primary mb-3 pagination-select" v-model="Filters.type" placeholder="Per page">
                  <el-option class="select-primary" v-for="item in Filters.typeOptions" :key="item.name"
                    :label="item.name" :value="item.value"></el-option>
                </el-select>
              </base-input>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="FilterShippingTypes()">
                Filtrar
              </button>
            </span>
            <span style="margin: 0 10pt">
              <button class="custom-btn-filter" @click="GetShippingTypes()">
                Limpar filtro
              </button>
            </span>
            <div v-if="checkFunctionality">
              <span style="margin: 0 10pt">
                <button class="custom-btn-add" @click="AddNew()"
                  v-if="getUserPermission('39_c')">Novo</button>
              </span>
            </div>
          </div>
          <svg v-if="showSpinner" class="spinner-lg" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30" />
          </svg>
          <el-table :data="queriedData">
            <el-table-column v-for="column in tableColumns" :key="column.label" :min-width="column.minWidth"
              :prop="column.prop" :label="column.label" align="center"></el-table-column>
            <el-table-column :min-width="135" align="right" label="Ações">
              <div slot-scope="props">
                <base-button class="like !important btn-link" type="primary" size="sm" icon
                  @click="ShowDetails(props.row)" v-if="getUserPermission('39_c')">
                  <i class="material-icons">mode_edit_outline</i>
                </base-button>
                <base-button class="edit btn-link" type="danger" size="sm" icon @click="Delete(props.row)"
                  v-if="getUserPermission('39_c')">
                  <i class="material-icons">delete_outline</i>
                </base-button>
              </div>
            </el-table-column>
          </el-table>
        </div>
        <div slot="footer" class="
            col-12
            d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
          <div class="col-5"></div>
          <div class="col-2">
            <label>
              Exibindo
              <span class="primary-text">{{ from + 1 }}</span> -
              <span class="primary-text">{{ to }}</span> de
              <span class="primary-text">{{ total }}</span> registros
            </label>
          </div>
          <div class="col-3">
            <base-pagination class="pagination-no-border" v-model="pagination.currentPage"
              :per-page="pagination.perPage" :total="total"></base-pagination>
          </div>
          <div class="col-2">
            <el-select class="select-primary mb-3 pagination-select" style="width: 100px !important"
              v-model="pagination.perPage" placeholder="Per page">
              <el-option class="select-primary" v-for="item in pagination.perPageOptions" :key="item" :label="item"
                :value="item"></el-option>
            </el-select>
          </div>
        </div>
      </card>
    </div>
    <div v-else class="row">
      <div class="col-12">
        <h4 slot="header" class="card-title">
          <base-button class="like !important btn-link" type="primary" size="sm" icon @click="HideDetails()">
            <i class="material-icons">arrow_back</i> </base-button>Cadastro
        </h4>
      </div>
      <div class="col-4">
        <card type="tasks" class="stacked-form">
          <h4 class="card-title">Nome</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input placeholder="Informar o nome" v-model="ShippingType.Name"></el-input>
            </base-input>
          </div>

          <h4 class="card-title">Tipo Ecommerce</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input placeholder="Informar o tipo ecommerce" v-model="ShippingType.TypeEcommerce"></el-input>
            </base-input>
          </div>

          <h4 class="card-title">Tipo de SAP</h4>
          <div class="col-12 table-full-width table-responsive">
            <base-input>
              <el-input placeholder="Informar o tipo de SAP" v-model="ShippingType.TypeSAP"
                onkeypress="return event.charCode >= 48 && event.charCode <= 57"></el-input>
            </base-input>
          </div>
        </card>
      </div>
      <div class="col-lg-12 col-sm-12">
        <UpdateLog :dataLog="dataLog" />
      </div>

      <div class="col-12">
        <div class="
            d-flex
            justify-content-center justify-content-between
            flex-wrap
          ">
          <div></div>
          <div>
            <button class="custom-btn-save" @click="Save()">Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>
import planMixin from "src/mixins/planMixin.js";
import swal from "sweetalert2";
import { BasePagination, BaseRadio } from "src/components";
import UpdateLog from "../../components/UpdateLog.vue";

export default {
  mixins: [planMixin],
  components: {
    BaseRadio,
    BasePagination,
    UpdateLog
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      dataLog: [],
      showDetails: false,
      selectedEcomm: "",
      toDisabled: "0",
      realm: null,
      Filters: {
        type: null,
        name: null,
        typeOptions: [
          { name: "Filtar por...", value: null },
          { name: "Nome", value: "NAME" },
          { name: "Tipo Ecommerce", value: "TypeEcommerce" },
          { name: "Tipo de SAP", value: "TypeSAP" },
        ],
      },

      tableColumns: [
        {
          prop: "ID",
          label: "Id",
          minWidth: 50,
        },
        {
          prop: "Name",
          label: "Nome",
          minWidth: 250,
        },
        {
          prop: "TypeEcommerce",
          label: "Tipo Ecommerce",
          minWidth: 250,
        },
        {
          prop: "TypeSAP",
          label: "Tipo de SAP",
          minWidth: 250,
        },
      ],
      pagination: {
        perPage: 10,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },

      tableData: [],
      searchedData: [],

      showSpinner: false,
      response: null,

      ShippingType: null,

      erpTable: [],
      paymentConditionTable: [],

      toInsert: true,
      showSpinner1: false,
      showSpinner2: false,

      checkFunctionality: true,
    };
  },
  async mounted() {
    this.selectedEcomm = this.$store.state.ecommerce.Name;
    this.GetShippingTypes();

    // await this.getPlan();
    // await this.getFunctionalities(this.isPlanControlActive);
  },
  methods: {
    //---- On load ---------------------------------------------------------
    GetShippingTypes: async function () {
      this.showSpinner = true;
      let response = await this.$bahngleis.get(
        "ShippingType/GetByEcommerceName?ecommerceName=" + this.selectedEcomm
      );

      this.tableData = response.data;
      this.showSpinner = false;
      this.Filters.name = null;
      this.Filters.type = null;
    },
    //---- Actions ---------------------------------------------------------
    FilterShippingTypes: async function () {
      if (this.Filters.name == null || this.Filters.type == null) {
        this.ShowWarningNotification("Por favor, informe ambos os filtros");
      } else {
        this.showSpinner = true;

        this.response = await this.$bahngleis.get(
          "ShippingType/GetAllWithFilter?ecommerceName=" +
          this.selectedEcomm +
          "&" +
          this.Filters.type +
          "=" +
          this.Filters.name
        );

        this.tableData = await this.response.data;
        this.showSpinner = false;
      }
    },

    ShowDetails: async function (shippingType) {
      this.ShippingType = shippingType;
      this.toInsert = false;
      this.toDisabled = "1";
      this.showDetails = !this.showDetails;

      let responseLog = await this.$bahngleis.get(
        "/LogAction/getlogsofentity?identity=" +
        shippingType.ID +
        "&entity=ShippingType"
      );

      this.dataLog = await responseLog.data;
    },

    AddNew: function () {
      this.ShippingType = {
        Id: 0,
        Name: null,
        TypeEcommerce: null,
        TypeSAP: null,
        ecommerceName: null,
      };
      this.toInsert = true;
      this.toDisabled = "0";
      this.showDetails = !this.showDetails;
    },

    Delete: function (ShippingType) {
      var vm = this;
      this.ShippingType = ShippingType;

      swal({
        title: "Gostaria de excluir o registro?",
        text: "Esse processo não poderá ser revertido.",
        showCancelButton: true,
        confirmButtonClass: "btn btn-success btn-fill",
        cancelButtonClass: "btn btn-danger btn-fill",
        cancelButtonText: "Não",
        confirmButtonText: "Sim",
      }).then((result) => {
        if (result.value) {
          var pos = this.tableData.indexOf(ShippingType);
          this.tableData.splice(pos, 1);
          vm.Del();
        }
      });
    },

    HideDetails: function () {
      this.ShippingType = null;

      this.showDetails = !this.showDetails;
    },
    Save: function () {
      if (!this.ShippingType.Name) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>Nome</b> do Produto"
        );
      } else if (!this.ShippingType.TypeEcommerce) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>Tipo Ecommerce</b>"
        );
      } else if (!this.ShippingType.TypeSAP) {
        this.ShowWarningNotification(
          "Por favor, informe o <b>Tipo de SAP</b> no Ecommerce"
        );
      } else {
        var vm = this;
        if (vm.toInsert) {
          vm.Insert();
        } else {
          vm.Update();
        }
      }
    },
    Insert: function () {
      this.ShippingType.ecommerceName = this.selectedEcomm;
      this.$bahngleis
        .post("ShippingType", this.ShippingType, {
          headers: {
            "Content-Type": "application/json",
          },
        }).then(async () => {
          swal({
            title: "Sucesso",
            text: "O cadastro foi inserido com sucesso",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          this.HideDetails();
          await this.GetShippingTypes();
        });
    },
    Del: function () {
      this.ShippingType.ecommerceName = this.selectedEcomm;

      this.$bahngleis
        .delete("ShippingType/" + this.ShippingType.ID)
        .then(function (response) {
          if (response.status === 200) {
            swal({
              title: "Sucesso",
              text: "O cadastro foi excluido com sucesso",
              type: "success",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          } else {
            swal({
              title: "Atenção",
              text: "Ocorreu um erro ao excluir o cadastro",
              type: "error",
              confirmButtonClass: "btn btn-success btn-fill",
              buttonsStyling: false,
            });
          }
        });
    },

    Update: function () {
      var vm = this;
      this.$bahngleis
        .put("ShippingType/", this.ShippingType)
        .then(function () {
          swal({
            title: "Sucesso",
            text: "O cadastro foi atualizado",
            type: "success",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
          this.HideDetails();
          this.GetShippingTypes();
          //vm.GetProductSyncs;
        })
        .catch(function () {
          swal({
            title: "Atenção",
            text: "Ocorreu um erro ao atualizar o cadastro",
            type: "error",
            confirmButtonClass: "btn btn-success btn-fill",
            buttonsStyling: false,
          });
        });
    },

    //---- Auxiliaries ---------------------------------------------------------
    ShowWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      });
      return;
    },
    getUserPermission(role) {
      return this.$keycloak.hasRealmRole(role);
    }
  },
};
</script>
<style>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.9s;
}

.fade-enter,
.fade-leave-to

/* .fade-leave-active em versões anteriores a 2.1.8 */
  {
  opacity: 0;
}
</style>
