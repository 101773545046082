<template>
  <div class="card-body">
    <div class="row">
      <div class="col-4">
        <base-input label="Nome" :required="true" v-model="form.name" :error="messageError(v$.form.name)" />
      </div>
      <div class="col-2">
        <base-input :disabled="true" label="Id Produto" v-model="form.id" />
      </div>
      <div class="col-2">
        <base-input :required="true" :error="messageError(v$.form.eCommerceId)" label="Id no Ecommerce" type="number"
          oninput="javascript: if (this.value.length > 18) this.value = this.value.slice(0, 18);"
          v-model="form.eCommerceId" />
      </div>
    </div>
    <div class="row">
      <div class="col-1">
        <h5 style="margin-left: 5px">Ativo?</h5>
        <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
          v-model="form.active" :sync="true" />
      </div>
      <div class="col-1">
        <h5 style="margin-left: 5px">Possui Variação?</h5>
        <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
          v-model="form.variation" :sync="true" />
      </div>
      <div class="col-1">
        <h5 style="margin-left: 5px">É KIT?</h5>
        <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
          v-model="form.isKit" :sync="true" />
      </div>
      <div class="col-2">
        <base-input label="Marca" :required="scheduleSyncProductsActive" :error="messageError(v$.form.brand.id)"
          v-model="form.brand.id" :select="true" @change="brandSelect">
          <option slot="options" style="color:black" v-for="(brand, index) in brands" :key="index" :label="brand.Name"
            :value="brand.ID"></option>
        </base-input>
      </div>
      <div class="col-2">
        <base-input label="Categoria" :required="scheduleSyncProductsActive" :error="messageError(v$.form.category)"
          v-model="form.category" />
      </div>
      <div class="col-2">
        <base-input label="Sub-Categoria" v-model="form.subCategory" />
      </div>
    </div>
    <base-input label="Descrição Curta" v-model="form.shortDescription" />
    <label>Descrição</label>
    <vue-editor
      v-model="form.description"
      :editorToolbar="customToolbar"
      :editorOptions="customEditorOptions"
    ></vue-editor>
  </div>
</template>
<script>
import { VueEditor } from "vue2-editor";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { mapActions, mapState, mapMutations } from "vuex";
import { EventBus } from "@/eventBus";
import validateMixin from "@/mixins/validateMixin";

export default {
  mixins: [validateMixin],
  components: {
    VueEditor,
  },
  data() {
    return {
      form: {
        name: "",
        ecommSkuId: null,
        eCommerceId: null,
        id: null,
        active: false,
        variation: false,
        isKit: false,
        brand: {
          id: null,
          name: ""
        },
        category: null,
        subCategory: null,
        shortDescription: "",
        description: "",
      },
      customToolbar: [
        ["bold", "italic", "underline"],
        [
          { align: "" },
          { align: "center" },
          { align: "justify" },
          { align: "right" },
        ],
        [{ list: "ordered" }, { list: "bullet" }],
      ],
      customEditorOptions: {
        formats: [
          "bold", "header", "italic", "link", "list", "blockquote", "image", "indent"
        ],
      },
      brands: [{
        ID: null,
        Name: 'Selecione a Marca'
      }],
      formChanged: false,
      renderedComponent: false
    };
  },
  validations() {
    return {
      form: {
        name: { required: helpers.withMessage("Nome obrigatório", required) },
        category: {
          required: helpers.withMessage("Categoria obrigatória", requiredIf(function () {
            return this.scheduleSyncProductsActive
          }))
        },
        eCommerceId: { required: helpers.withMessage("Id no Ecommerce obrigatório", required) },
        brand: {
          id: {
            required: helpers.withMessage("Marca obrigatória", requiredIf(function () {
              return this.scheduleSyncProductsActive
            }))
          }
        }
      },
    };
  },
  computed: {
    ...mapState("product", ["productCurrent", "scheduleSyncProductsActive"]),
  },
  methods: {
    ...mapActions('product', ['getBrands']),
    ...mapMutations('product', ['SET_FORM_CHANGED']),
    fillForm() {
      if (this.productCurrent) {
        this.form.name = this.productCurrent.Name;
        this.form.ean = this.productCurrent.Ean;
        this.form.ecommSkuId = this.productCurrent.ecommSkuId;
        this.form.id = this.productCurrent.Id;
        this.form.active = this.productCurrent.Active;
        this.form.variation = this.productCurrent.Variation;
        this.form.category = this.productCurrent.Category;
        this.form.subCategory = this.productCurrent.SubCategory;
        this.form.shortDescription = this.productCurrent.ShortDescription;
        this.form.description = this.productCurrent.Description;
        this.form.brand.id = this.productCurrent.hasOwnProperty('Brand') ? this.productCurrent.Brand.Id : null;
        this.form.brand.name = this.productCurrent.hasOwnProperty('Brand') ? this.productCurrent.Brand.Name : null;
        this.form.eCommerceId = this.productCurrent.ECommerceId;
        this.form.isKit = this.productCurrent.IsKit;
      };
      this.formChanged = false;
    },
    value() {
      return { ...this.form };
    },
    brandSelect() {
      this.form.brand.name = this.brands.find(x => x.ID == this.form.brand.id).Name;
    }
  },
  created() {
    EventBus.$on("ProductRegisterEdition", () => this.fillForm());
  },
  watch: {
    form: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.formChanged == false && this.renderedComponent) {
          this.formChanged = true;
          this.SET_FORM_CHANGED(true);
        }
      }
    }
  },
  async mounted() {
    this.brands.push(...await this.getBrands());
    setTimeout(() => {
      this.renderedComponent = true;
    }, 2000);
  }
};
</script>
<style scoped>
.card-body {
  margin: 10px;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
}
</style>
