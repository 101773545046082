<template>
  <div class="flex-fill card-body">
    <h4>Integração com E-commerce</h4>
    <div class="d-flex ">
      <h5 class="p-2">Situação:</h5>
      <div>
        <div :class="getClassStatus">{{ getStatus }}</div>
      </div>
    </div>
    <div class="d-flex" v-if="productCurrent.IntegrationStatus && productCurrent.IntegrationStatus.length > 0">
      <h5 class="p-2">Última integração realizada:</h5>
      <h5 class="p-2" style="color: rgb(0, 241, 230)">{{ getLastIntegration() }}</h5>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/eventBus";
import { helpers, required } from "@vuelidate/validators";
import { mapState } from 'vuex';
import constants from "@/util/constants"

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState("product", ["productCurrent"]),
    getClassStatus() {
      if(this.productCurrent.IntegrationStatus && this.productCurrent.IntegrationStatus.length > 0){

        let integration = this.productCurrent.IntegrationStatus[this.productCurrent.IntegrationStatus.length - 1];

        switch (integration.Status) {
          case constants.IntegrationStatus.Pending:
            return "status statusScheduled"
          case constants.IntegrationStatus.Processing:
            return "status statusProcessing"
          case constants.IntegrationStatus.Completed:
            return "status statusComplete"
          case constants.IntegrationStatus.Error:
            return "status statusError"
          default:
            return "status statusDefault";
        }
      }

      return "status statusDefault";
    },
    getStatus(){
      if(this.productCurrent.IntegrationStatus && this.productCurrent.IntegrationStatus.length > 0){

        let integration = this.productCurrent.IntegrationStatus[this.productCurrent.IntegrationStatus.length - 1];

        switch (integration.Status) {
          case constants.IntegrationStatus.Pending:
            return "Pendente"
          case constants.IntegrationStatus.Processing:
            return "Processando"
          case constants.IntegrationStatus.Completed:
            return "Completo"
          case constants.IntegrationStatus.Error:
            return "Erro"
          default:
            return "Não Integrado";
        }
      }

      return "Não Integrado";
    },
    getLastIntegration(){
      if(this.productCurrent.IntegrationStatus && this.productCurrent.IntegrationStatus.length > 0)
        return this.productCurrent.IntegrationStatus[this.productCurrent.IntegrationStatus.length - 1].LastUpdatedTime

      return " ---- ";
    }
  },
};
</script>
<style scoped>
.card-body {
  margin: 10px;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
}
</style>