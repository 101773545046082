<template>
  <div class="row">
    <div class="col-12">
      <card type="tasks" class="stacked-form">
        <div class="col-9">
          <div class="row">
            <div class="col-6">
              <base-input>
                <label>Nome:</label>
                <el-input v-model="customEvent.Name" />
              </base-input>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center">
              <base-input>
                <label>Status:</label><br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc"
                               input type="checkbox" v-model="customEvent.Active" :sync="true" />
              </base-input>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center">
              <base-input>
                <label>Evento de pedido enviado?</label><br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc"
                               input type="checkbox" v-model="customEvent.IsOrderDispatchedEvent" :sync="true" />
              </base-input>
            </div>
            <div class="col-2 d-flex align-items-center justify-content-center">
              <base-input>
                <label>Evento de pedido entregue?</label><br />
                <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc"
                               input type="checkbox" v-model="customEvent.IsOrderDeliveredEvent" :sync="true" />
              </base-input>
            </div>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import { EventBus } from "@/eventBus";

export default {
  computed: {
    ...mapState("customEvent", ["customEventCurrent"]),
    ...mapState(["ecommerce"]),
  },
  data() {
    return {
      customEvent: {
        EcommerceName: "",
        Name: "",
        Active: false,
        IsOrderDispatchedEvent: false,
        IsOrderDeliveredEvent: false
      }
    };
  },
  methods: {
    save() {
      this.$emit("save");
    },
    validate() {
      if (this.$isNullOrEmpty(this.customEvent.Name)) {
        this.$showError("Nome obrigatório.");
        return false;
      }
      else
        return true;
    },
    getValue() {
      return this.customEvent;
    },
    async load() {
      this.customEvent = this.customEventCurrent;
    }
  },
  mounted() {
    EventBus.$on("loadCustomEvent", async () => this.load());
  }
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}
</style>
