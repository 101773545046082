<template>
  <div>
    <div class="row">
      <arrow-back
        :title="'Produto Variante'"
        :route-name="'ProductVariation'"
      ></arrow-back>
    </div>
    <card card-body-classes="">
      <h4 slot="header" class="card-title">
        Associar atributo no produto variante
      </h4>
      <div>
        <card>
          <blockquote class="blockquote mb-0">
            <div class="row">
              <base-label> Nome: {{ product.Name }}</base-label> -
              <base-label> SKU: {{ product.SKU }}</base-label>
            </div>
            <div class="row">
              <span style="font-size: 14px; color: #fff"
                >Registros associados:
              </span>
            </div>
            <div class="row">
              <ul style="list-style-type: none">
                <li
                  v-for="item in product.Children"
                  :key="item.Id"
                  style="color: #525f7f; margin-left: 0px"
                >
                  <base-label> Nome: {{ item.Name }}</base-label> -
                  <base-label> SKU: {{ item.SKU }}</base-label>
                </li>
              </ul>
            </div>
          </blockquote>
        </card>
      </div>
    </card>
    <div class="row">
      <div class="col-4">
        <card type="" class="stacked-form">
          <h4 class="card-title">Nome no E-Commerce</h4>
          <div class="col-12">
            <base-input>
              <el-input
                placeholder="Nome"
                v-model="variationAttribute.Name"
              ></el-input>
            </base-input>
          </div>
        </card>
      </div>
      <div class="col-4">
        <card class="stacked-form">
          <h4 class="card-title">Referência na API do E-Commerce</h4>
          <div class="col-12">
            <base-input>
              <el-input
                placeholder="Nome"
                v-model="variationAttribute.ECommerceReference"
              ></el-input>
            </base-input>
          </div>
        </card>
      </div>
      <div class="col-4">
        <card class="stacked-form">
          <h4 class="card-title">Referência no SAP</h4>
          <div class="col-12">
            <base-input>
              <el-input
                placeholder="Nome"
                v-model="variationAttribute.SAPReference"
              ></el-input>
            </base-input>
          </div>
        </card>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div
          class="d-flex justify-content-center justify-content-between flex-wrap"
        >
          <div></div>
          <div>
            <button class="custom-btn-save" @click="save()">Adicionar</button>
          </div>
        </div>
      </div>
    </div>
    <br />
    <div class="row">
      <div class="col-12">
        <card card-body-classes="table-full-width">
          <el-table :data="queriedData" scope="scope">
            <el-table-column
              v-for="column in tableColumns"
              :key="column.label"
              :min-width="column.minWidth"
              :prop="column.prop"
              :label="column.label"
              :formatter="column.formatter"
              :empty-text="''"
              align="center"
            >
            </el-table-column>

            <el-table-column :min-width="'100px'" align="right" label="Ações">
              <div slot-scope="props">
                <div>
                  <base-button
                    class="like btn-link"
                    type="primary"
                    size="sm"
                    icon
                    @click="remove(props.row)"
                  >
                    <i class="tim-icons icon-simple-delete"></i>
                  </base-button>
                </div>
              </div>
            </el-table-column>
          </el-table>
        </card>
      </div>
    </div>
  </div>
</template>

<script>
import ArrowBack from "src/components/ArrowBack/ArrowBack";

import {
  //   BaseProgress,
  BaseSwitch,
  //   Slider,
  TagsInput,
} from "src/components/index";
import { BasePagination, Card } from "src/components";
import swal from "sweetalert2";

export default {
  components: {
    ArrowBack,
    BasePagination,
    Card,
  },
  computed: {
    queriedData() {
      return this.product.VariantAttributes
        ? this.product.VariantAttributes
        : [];
    },
  },
  data() {
    return {
      currentEcommerce: undefined,
      product: {
        Id: null,
        Name: null,
        SKU: null,
      },
      variationAttribute: {
        name: null,
        ECommerceReference: null,
        SAPReference: null,
      },

      tableColumns: [
        {
          prop: "Name",
          label: "Nome no E-Commerce",
          minWidth: 100,
        },
        {
          prop: "EcommerceReference",
          label: "Referência na API do E-Commerce",
          minWidth: 100,
        },
        {
          prop: "SAPReference",
          label: "Referência no SAP",
          minWidth: 100,
        },
      ],
      tableData: [],
    };
  },
  methods: {
    save: async function () {
      if (
        !this.variationAttribute.Name ||
        !this.variationAttribute.ECommerceReference ||
        !this.variationAttribute.SAPReference
      ) {
        this.showWarningNotification("Por favor, informe os campos");
      }

      this.variationAttribute.ProductId = this.product.Id;

      let productResponse = await this.$bahngleis.post(
        "ProductSync/" + this.product.Id + "/attribute",
        this.variationAttribute
      );
      this.onLoad();
    },
    remove: async function (row) {
      let productResponse = await this.$bahngleis.delete(
        "ProductSync/" + this.product.Id + "/attribute/" + row.Id,
        this.variationAttribute
      );
      this.onLoad();
    },
    onLoad: async function () {
      let productResponse = await this.$bahngleis.get(
        "ProductSync/" + this.$route.params.id,
        {
          params: { ecommerceName: this.currentEcommerce },
        }
      );
      this.product = productResponse.data;
    },
    showWarningNotification: function (message) {
      this.$notify({
        message: message,
        timeout: 3000,
        icon: "tim-icons icon-bell-55",
        horizontalAlign: "right",
        verticalAlign: "top",
        type: "danger",
      });
      return;
    },
  },
  mounted() {
    this.currentEcommerce = this.$store.state.ecommerce.Name;
    this.onLoad();
  },
};
</script>

<style>
</style> 