<template>
  <div class="card-body">
    <div class="col-6">
      <div class="col-12">
        <base-input label="Tipo do Contas a Receber no SAP">
          <el-select class="select-primary mb-3 pagination-select" v-model="form.PaymentMethodType"
            placeholder="Informe o Tipo do Contas a Receber no SAP">
            <el-option class="select-primary" v-for="item in paymentMethodAccountTypes" :key="item.Key"
              :label="item.Label" :value="item.Key"></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12">
        <base-input v-if="!enableSAP4HANA" label="Número da Conta Contábil">
          <el-input placeholder="Informe o Número da Conta Contábil" v-model="form.Account"></el-input>
        </base-input>
        <base-input v-else label="Número da Conta Contábil">
          <el-select class="select-primary mb-3 pagination-select" v-model="form.Account"
            placeholder="Informe o Número da Conta Contábil" filterable>
            <el-option class="select-primary" v-for="item in paymentMethodTypeList" :key="item.Code"
              :label="item.Code + ' - ' + item.Name" :value="item.Code"></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12">
        <base-input v-if="!enableSAP4HANA" label="Código do Cartão no SAP">
          <el-input placeholder="Informe o Código do Cartão no SAP" v-model="form.CreditCardCode"></el-input>
        </base-input>
        <base-input v-else label="Código do Cartão no SAP">
          <el-select class="select-primary mb-3 pagination-select" v-model="form.CreditCardCode"
            placeholder="Informe o Código do Cartão no SAP" filterable>
            <el-option class="select-primary" v-for="item in creditCardCodeList" :key="item.CreditCardCode"
              :label="item.CreditCardCode + ' - ' + item.CreditCardName" :value="item.CreditCardCode"></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12">
        <base-input v-if="!enableSAP4HANA" label="Método de Pagamento para Cartão">
          <el-input placeholder="Código" v-model="form.PaymentMethodCode"></el-input>
        </base-input>
        <base-input v-else label="Método de Pagamento para Cartão">
          <el-select class="select-primary mb-3 pagination-select" v-model="form.PaymentMethodCode"
            placeholder="Informe o Método de Pagamento para Cartão" filterable>
            <el-option class="select-primary" v-for=" item in paymentMethodCodeList " :key="item.PaymentMethodCode"
              :label="item.PaymentMethodCode + ' - ' + item.Name" :value="item.PaymentMethodCode"></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12">
        <base-input label="Definir campo do Comprovante de Pagamento">
          <el-select class="select-primary mb-3 pagination-select" v-model="form.VoucherType"
            placeholder="Informe o tipo de comprovante" filterable>
            <el-option class="select-primary" v-for="(item, index) in voucherTypes" :key="index" :label="item.label"
              :value="item.value"></el-option>
          </el-select>
        </base-input>
      </div>
      <div class="col-12">
        <base-input label="Dividir parcelas do cartão?">
          <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
            v-model="form.IsSplitPayments" :sync="true" />
        </base-input>
      </div>
      <div class="col-12">
        <base-input label="Nome ou Sigla da Marca">
          <el-input placeholder="Informe o Nome ou Sigla da Marca" v-model="form.Brand"></el-input>
        </base-input>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { EventBus } from "@/eventBus";
import validateMixin from "@/mixins/validateMixin";
import constants from '@/util/constants';

export default {
  mixins: [validateMixin],
  data() {
    return {
      form: {
        Account: null,
        Brand: null,
        CreditCardCode: null,
        IsSplitPayments: false,
        PaymentMethodCode: null,
        PaymentMethodType: "",
        VoucherType: null,
      },
      isSapHana: false,
      enableSAP4HANA: false,
      paymentMethodAccountTypes: constants.PaymentMethodTypes,
      paymentMethodTypeList: [],
      creditCardCodeList: [],
      paymentMethodCodeList: [],
      voucherTypes: [
        { label: "NSU", value: 0 },
        { label: "TID", value: 1 },
        { label: "Número do Pedido", value: 2 },
      ]
    };
  },
  computed: {
    ...mapState("paymentMethod", ["paymentMethodCurrent", "creditCardCodes", "paymentMethodCodes", "paymentMethodTypes"]),
    ...mapState(['ecommerce']),
  },
  methods: {
    fillForm() {
      if (this.paymentMethodCurrent) {
        this.form.Account = this.paymentMethodCurrent.Account;
        this.form.Brand = this.paymentMethodCurrent.Brand;
        this.form.CreditCardCode = !isNaN(this.paymentMethodCurrent.CreditCardCode) ? parseInt(this.paymentMethodCurrent.CreditCardCode) : this.paymentMethodCurrent.CreditCardCode;
        this.form.IsSplitPayments = this.paymentMethodCurrent.IsSplitPayments;
        this.form.PaymentMethodCode = !isNaN(this.paymentMethodCurrent.PaymentMethodCode) ? parseInt(this.paymentMethodCurrent.PaymentMethodCode) : this.paymentMethodCurrent.PaymentMethodCode;
        this.form.PaymentMethodType = this.paymentMethodCurrent.PaymentMethodType;
        this.form.VoucherType = this.paymentMethodCurrent.VoucherType;
      }
    },
    value() {
      return { ...this.form };
    },
    GetSapField: async function () {
      this.creditCardCodeList = this.creditCardCodes;
      this.paymentMethodCodeList = this.paymentMethodCodes;
      this.paymentMethodTypeList = this.paymentMethodTypes;

      this.creditCardCodeList.sort((a, b) => a.CreditCardCode - b.CreditCardCode);
      this.paymentMethodCodeList.sort((a, b) => a.PaymentMethodCode - b.PaymentMethodCode);
      this.paymentMethodTypeList.sort((a, b) => {
        if (a.Code < b.Code) {
          return -1;
        }
        if (a.Code > b.Code) {
          return 1;
        }
        return 0;
      });
    },
    GetErpPlatformType(key) {
      this.isSapHana = ("Sap Hana").includes(constants.ErpPlatform.find((a) => a.Value === key).Label);
    },
    EnableErpReferenceManual() {
      if (this.isSapHana) {
        this.enableSAP4HANA = true;
      } else {
        this.enableSAP4HANA = false;
      }
    },
  },
  created() {
    EventBus.$on("PaymentMethodEdition", () => this.fillForm());
  },
  async mounted() {
    await this.GetErpPlatformType(this.ecommerce.Erp.Platform);

    await this.GetSapField();

    this.EnableErpReferenceManual();

  }
};
</script>

<style scoped>
.card-body {
  margin: 10px;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
}
</style>
