<template>
  <div class="btn-wrap">
    <el-image
      style="width: 100px; height: 100px"
      :src="source"
      fit="cover"
    ></el-image>
    <p>{{ image.nome }}</p>
    <base-button @click="removeImage" round icon type="primary" class="btn">
      <i class="tim-icons icon-simple-remove"></i>
    </base-button>
  </div>
</template>
<script>
export default {
  props: {
    image: {
      type: Object,
    },
  },
  computed: {
    source(){
      return this.image.type === 3 ? 'https://api.iconify.design/ic:baseline-video-file.svg?color=%23b32323' : this.image.url;
    }
  },
  methods: {
    removeImage() {
      this.$emit("evtRemoveImage", this.image.index);
    },
  },
};
</script>
<style scoped>
.btn-wrap {
  position: relative;
  text-align: center;
  margin: 20px;
}
.btn-wrap .btn {
  position: absolute;
  z-index: 1;
  top: 5%;
  left: 95%;
  width: 30px;
  height: 30px;
  padding: 0;
  margin: -15px 0 0 -30px;
  border: 0 none;
}
</style>