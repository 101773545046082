<template>
    <Modal :modalContentClasses="'card'" :showClose="false" :show="show">
        <template v-slot:header>
            <h4 class="card-title">Adicionar Condição</h4>
        </template>
        <div class="row">
                <div class="col-12 col-sm-1">
                    <base-input label="Ativo">
                        <toggle-button
                            class="ma-3"
                            :labels="{ checked: 'ON', unchecked: 'OFF' }"
                            color="#7dd7dc"
                            input
                            type="checkbox"
                            v-model="form.Active"
                            :sync="true"
                            />
                    </base-input>
                </div>
                <div class="col-12 col-sm-12">
                <base-input label="Campo alvo no ecommerce *" :error="errorsCustomFields.platformFieldTarget">
                    <el-input
                    placeholder="Campo no ecommerce em que será aplicada a condição"
                    v-model="form.PlatformFieldTarget"
                    ></el-input>
                </base-input>
                </div>
                <div class="col-12 col-sm-12">
                <base-input label="Tipo da condição *" :error="errorsCustomFields.conditionType">
                    <el-select
                    class="select-primary"
                    v-model="form.ConditionType"
                    >
                    <el-option
                        v-for="option in conditionTypes"
                        :value="option.Key"
                        :label="option.Label"
                        :key="option.Key"
                    ></el-option>
                    </el-select>
                </base-input>
                </div>
                <div class="col-12 col-sm-12">
                <base-input label="Valor da condição *" :error="errorsCustomFields.conditionValue">
                    <el-input
                    placeholder="Valor da condição"
                    v-model="form.ConditionValue"
                    ></el-input>
                </base-input>
                </div>
        </div>
        <template v-slot:footer>
            <button
                type="button"
                @click="addCondition"
                class="custom-btn-save mx-auto "
                aria-label="Close"
            >
                Adicionar
            </button>
            <button
                type="button"
                @click="closeModal"
                class="custom-btn-add mx-auto"
                aria-label="Close"
            >
                Cancelar
        </button>
    </template>
    </Modal>
</template>
<script>
import Modal from '@/components/Modal'
import constants from '@/util/constants'

export default {
    components : {
        Modal
    },
    props : {
        show : {
            type : Boolean,
            default : false
        },
    },
    data(){
        return {
            form : {
                PlatformFieldTarget : null,
                ConditionType : null,
                ConditionValue : null,
                Active : true
            },
            errorsCustomFields : {
                fieldType : null,
                documentType : null,
                rule : null,
                erpField : null,
                defaultValue : null,
            },
            conditionTypes : [],
        }
    },
    methods : {
        addCondition(){
            if(!this.validate())
                return

            this.$emit("evtAddedCondition", this.form);
            this.form = {
                PlatformFieldTarget : null,
                ConditionType : null,
                ConditionValue : null,
                Active : true
            };
        },
        closeModal(){
            this.$emit("evtClose");
        },
        validate(){
            let valid = true;
            let error = "Campo obrigatório"
            if(this.$isNullOrEmpty(this.form.ConditionType)){
                this.errorsCustomFields.conditionType = error;
                valid = false;
            }
            if(this.$isNullOrEmpty(this.form.PlatformFieldTarget)){
                this.errorsCustomFields.platformFieldTarget = error;
                valid = false;
            }
            if(this.$isNullOrEmpty(this.form.ConditionValue) && this.form.ConditionType !== 8){
                this.errorsCustomFields.conditionValue = error;
                 valid = false;
            }

            return valid;
        }
    },
    mounted(){
        this.conditionTypes = constants.CustomFieldConditionType;
    }
}
</script>
