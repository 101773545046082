<template>
  <div class="row">
    <div class="col-12">
      <h4 slot="header" class="card-title">
        <base-button class="like !important btn-link" type="primary" size="sm" icon @click="$router.go(-1)">
          <i class="material-icons">arrow_back</i>
        </base-button> Detalhes Evento
      </h4>
    </div>
    <div class="col-12">
      <CustomEventForm ref="form" />
    </div>
    <div class="col-lg-12 col-sm-12">
      <UpdateLog :dataLog="logs" />
    </div>
    <div class="col-12">
      <div class="d-flex justify-content-center justify-content-between flex-wrap">
        <div></div>
        <div>
          <button class="custom-btn-save" @click="update">Atualizar</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapMutations, mapState } from "vuex";
import CustomEventForm from "../components/CustomEventForm.vue";
import UpdateLog from "../../../components/UpdateLog.vue";
import { EventBus } from "@/eventBus";

export default {
  components: {
    CustomEventForm,
    UpdateLog
  },
  computed: {
    ...mapState('customEvent', ['customEvents', 'logs'])
  },
  methods: {
    ...mapActions('customEvent', ['updateCustomEvent', 'getLogCustomEvent']),
    ...mapMutations('customEvent', ['SET_CUSTOMEVENT_CURRENT']),
    getCustomEventCurrent() {
      let customEventId = this.$route.query.id;
      let customEvent = this.customEvents.Items.find(a => a.Id === customEventId);
      this.SET_CUSTOMEVENT_CURRENT(customEvent);
      this.getLogCustomEvent(customEventId);
      EventBus.$emit("loadCustomEvent");
    },
    async update() {
      let form = this.$refs.form;
      if (!form.validate())
        return;

      this
        .updateCustomEvent(form.getValue())
        .then(() => {
          this.$showSuccess("O cadastro foi atualizado com sucesso");
          this.$router.push({ name: 'CustomEvent' });
        })
        .catch(error => { this.$showError(error) });
    }
  },
  mounted() {
    this.getCustomEventCurrent();
  }
}
</script>
