<template>
  <div>
    <card card-body-classes="table-full-width">
      <h4 slot="header" class="card-title">Produto Variante</h4>
      <div>
        <!-- <div class="col-xs-12">
          <button class="custom-btn-add float-right" @click="addNew()">
            Novo
          </button>
        </div> -->
        <el-table :data="queriedData" scope="scope">
          <el-table-column
            v-for="column in tableColumns"
            :key="column.label"
            :min-width="column.minWidth"
            :prop="column.prop"
            :label="column.label"
            :formatter="column.formatter"
            align="center"
          >
          </el-table-column>

          <el-table-column :min-width="'100px'" align="right" label="Ações">
            <div slot-scope="props">
              <base-button
                class="like btn-link"
                type="primary"
                size="sm"
                icon
                @click="attach(props.row)"
              >
                <i class="tim-icons icon-link-72"></i>
              </base-button>
              <base-button
                class="like btn-link"
                type="primary"
                size="sm"
                icon
                @click="remove(props.row)"
              >
                <i class="fas fa-minus-circle"> a</i>
              </base-button>
            </div>
          </el-table-column>
        </el-table>
      </div>
      <div
        slot="footer"
        class="col-12 d-flex justify-content-center justify-content-between flex-wrap"
      >
        <div class="col-5"></div>
        <div class="col-2">
          <label>
            Exibindo
            <span class="primary-text">{{ from + 1 }}</span> -
            <span class="primary-text">{{ to }}</span> de
            <span class="primary-text">{{ total }}</span> registros
          </label>
        </div>
        <div class="col-3">
          <base-pagination
            class="pagination-no-border"
            v-model="pagination.currentPage"
            :per-page="pagination.perPage"
            :total="total"
          ></base-pagination>
        </div>
        <div class="col-2">
          <el-select
            class="select-primary mb-3 pagination-select"
            style="width: 100px !important"
            v-model="pagination.perPage"
            placeholder="Per page"
          >
            <el-option
              class="select-primary"
              v-for="item in pagination.perPageOptions"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import {
  //   BaseProgress,
  BaseSwitch,
  //   Slider,
  TagsInput,
} from "src/components/index";
import { BasePagination } from "src/components";
import swal from "sweetalert2";

export default {
  components: {
    BasePagination,
  },
  computed: {
    queriedData() {
      let result = this.tableData;
      if (this.searchedData.length > 0) {
        result = this.searchedData;
      }
      return result.slice(this.from, this.to);
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.searchedData.length > 0
        ? this.searchedData.length
        : this.tableData.length;
    },
  },
  data() {
    return {
      currentEcommerce: undefined,
      pagination: {
        perPage: 5,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50],
        total: 0,
      },
      searchQuery: "",
      propsToSearch: ["name"],

      tableColumns: [
        {
          prop: "SKU",
          label: "Sku",
          minWidth: 100,
        },
        {
          prop: "Name",
          label: "Nome",
          minWidth: 100,
          formatter: function (row, column, cellValue, index) {
            return cellValue ? cellValue : "[S/N]";
          },
        },
      ],
      tableData: [],
      searchedData: [],
    };
  },
  methods: {
    onLoad: async function () {
      let response = await this.$bahngleis.get("ProductSync", {
        params: { ecommerceName: this.currentEcommerce },
      });
      this.tableData = response.data;
    },
    attach: function (row) {
      this.$router.push({
        name: "ProductVariationAttach",
        params: { id: row.Id },
      });
    },
    remove: async function (row) {
      swal
        .fire({
          title: "Tem certeza?",
          text: "Item será removido",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Sim",
          cancelButtonText: "Não",
        })
        .then(async (result) => {
          if (result.value === true) {
            let vm = this;
            await this.$bahngleis
              .delete("ProductSync/" + row.Id)
              .then(function () {
                swal.fire({
                  title: "Deletado!",
                  icon: "success",
                  timer: 2000,
                });
                vm.onLoad();
              });
          }
        });
    },
  },
  mounted() {
    this.currentEcommerce = this.$store.state.ecommerce.Name;
    this.onLoad();
  },
};
</script>

<style>
</style> 