<template>
  <div class="flex-fill card-body">
    <div class="d-flex">
      <base-input class="m-1" label="Atributo" :required="scheduleSyncProductsActive"
        :error="messageError(v$.attributeList)">
        <el-select class="select-primary" placeholder="Atributo" v-model="attribute.attributeId">
          <el-option class="select-primary" v-for="(attr, index) in attributes" :key="index" :label="attr.Name"
            :value="attr.Id"></el-option>
        </el-select>
      </base-input>
      <base-input class="m-1" :disabled="showValueAttribute" label="Valor do Atributo" v-model="attribute.value" />
      <base-button @click="addAttribute" round icon type="primary" class="mt-4 ml-3">
        <i class="tim-icons icon-simple-add"></i>
      </base-button>
    </div>
    <div>
      <el-table :data="attributeList" style="width: 100%">
        <el-table-column prop="index" label="#" :width="90" align="center"></el-table-column>
        <el-table-column prop="attributeName" label="Atributo" align="center"></el-table-column>
        <el-table-column label="Descrição" align="center">
          <div slot-scope="props">
            {{ getDescriptionAttribute(props.row.attributeName) }}
          </div>
        </el-table-column>
        <el-table-column label="Grupo" align="center">
          <div slot-scope="props">
            {{ getGroupAttribute(props.row.attributeName) }}
          </div>
        </el-table-column>
        <el-table-column align="center" prop="value" label="Valor do Atributo"></el-table-column>
        <el-table-column :min-width="135" align="center" label="Ações">
          <div slot-scope="props">
            <base-button class="edit btn-link" type="danger" size="sm" icon @click="remove(props.row)">
              <i class="material-icons">delete_outline</i>
            </base-button>
          </div>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script lang="js">
import { EventBus } from "@/eventBus";
import { mapActions, mapState, mapMutations } from "vuex";
import validateMixin from "@/mixins/validateMixin";
import { helpers, requiredIf } from "@vuelidate/validators";

export default {
  mixins: [validateMixin],
  data() {
    return {
      attribute: {
        attributeId: null,
        attributeName: null,
        value: null,
      },
      attributeList: [],
      attributes: [],
      formChanged: false,
      renderedComponent: false
    };
  },
  validations() {
    return {
      attributeList: {
        required: helpers.withMessage("Adicione um atributo", requiredIf(function () {
          return this.scheduleSyncProductsActive
        }))
      },
    };
  },
  computed: {
    ...mapState("product", ["productCurrent", "scheduleSyncProductsActive"]),
    showValueAttribute() {
      return this.attribute.attributeId === null;
    }
  },
  methods: {
    ...mapActions("product", ["getAttributes", "removeSpecification"]),
    ...mapMutations('product', ['SET_FORM_CHANGED']),
    value() {
      return { specifications: this.attributeList };
    },
    addAttribute() {
      if (!this.attribute.attributeId) {
        this.$showError("Selecione o Atributo");
        return;
      }

      if (!this.attribute.value) {
        this.$showError("Informe o valor do Atributo");
        return;
      }

      if (this.attributeList.some(a => a.attributeId === parseInt(this.attribute.attributeId))) {
        this.$showError("Atributo já adicionado");
        return;
      }

      let attr = this.attributes.find(a => a.Id === parseInt(this.attribute.attributeId));
      this.attribute.attributeName = attr.Name;


      this.attributeList = this.$createListWithIndex(
        this.attributeList,
        this.attribute
      );

      this.attribute = {
        attributeId: "",
        value: "",
      };
    },
    reIndexAttributes() {
      this.attributeList.forEach((attr, index) => {
        attr.index = index + 1;
      });
    },
    remove(row) {
      this.removeSpecification(row.id).then(res => {
        this.attributeList = this.attributeList.filter(
          (attr) => attr.index != row.index
        );
        this.reIndexAttributes();
        this.$showSuccess("Atributo removido com sucesso!");
      }).catch(res => {
        this.$showError(res.message);
      });
    },
    fillForm() {
      this.attributeList = [];
      if (this.productCurrent.Specifications) {
        this.productCurrent.Specifications.forEach(a => {
          let attr = this.attributes ? this.attributes.find(x => x.Id === a.AttributeId) : null;
          if (attr) {
            this.attributeList.push({ attributeId: a.AttributeId, attributeName: attr.Name, value: a.Value, id: a.Id });
          }
        });
        this.reIndexAttributes();
      }
      this.formChanged = false;
    },
    getDescriptionAttribute(name) {
      let attr = this.attributes.find(a => a.Name === name);
      return attr ? attr.Description : " --- ";
    },
    getGroupAttribute(name) {
      let attr = this.attributes.find(a => a.Name === name);
      return attr ? attr.AttributeGroupName : " --- ";
    }
  },
  created() {
    EventBus.$on("ProductRegisterEdition", () => setTimeout(() => {
      this.fillForm()
    }, 1000));
  },
  watch: {
    attributeList: {
      deep: true,
      handler(newValue, oldValue) {
        if (this.formChanged == false && this.renderedComponent) {
          this.formChanged = true;
          this.SET_FORM_CHANGED(true);
        }
      }
    }
  },
  async mounted() {
    await this.getAttributes({ type: 'Product', status: 'true' }).then(res => {
      this.attributes = res.data.Attributes;
    });

    setTimeout(() => {
      this.renderedComponent = true;
    }, 2000);
  },
};
</script>
<style scoped>
.card-body {
  margin: 10px;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
}
</style>
