<template>
  <card type="tasks" class="stacked-form">
    <div class="row">
      <base-input class="col-md-5 mt-3" label="Nome" :required="true" v-model="priceList.Name" />

      <base-input v-if="showOnlyForSAPHANA()" class="col-md-3 mt-3" label="Código" :select="true" :required="true">
        <el-select class="select-primary" v-model="priceList.PriceListCode" filterable @change="priceListCodeSelect">
          <el-option class="select-primary" v-for="(erpPriceList, index) in erpPriceLists" :key="index"
            :label="erpPriceList.Name" :value="erpPriceList.Code"></el-option>
        </el-select>
      </base-input>

      <base-input v-else class="col-md-3 mt-3" label="Código" :required="true" v-model="priceList.PriceListCode" />

      <base-input class="col-md-2 mt-3" label="Ativo">
        <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox" class="mt-3"
          v-model="priceList.Active" :sync="true" />
      </base-input>
      <base-input class="col-lg-2 col-md-3 mt-3" label="Lista adicional">
        <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox" class="mt-3"
          v-model="priceList.AdditionalList" :sync="true" />
      </base-input>
      <base-input class="col-lg-1 col-md-3 mt-3" label="Principal">
        <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox" class="mt-3"
          v-model="priceList.IsMain" :sync="true" />
      </base-input>
      <base-input class="col-lg-1 col-md-3 mt-3" label="B2B">
        <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox" class="mt-3"
          v-model="priceList.IsB2B" :sync="true" />
      </base-input>
      <base-input class="col-lg-1 col-md-3 mt-3" label="Desconto">
        <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox" class="mt-3"
          v-model="priceList.IsDiscount" :sync="true" />
      </base-input>
      <base-input class="col-xl-2 col-md-4 mt-3" label="Id da Lista de Preço no Ecommerce"
        v-model="priceList.EcommercePriceListId" />
    </div>
  </card>
</template>
<script>
import { mapState } from 'vuex';
import { EventBus } from "@/eventBus";
import constants from '@/util/constants';

export default {
  computed: {
    ...mapState('priceList', ['priceListCurrent', 'erpPriceLists']),
    ...mapState(['ecommerce']),
  },
  data() {
    return {
      priceList: {
        EcommerceName: "",
        Name: "",
        PriceListCode: "",
        Active: false,
        AdditionalList: false,
        IsMain: false,
        IsB2B: false,
        IsDiscount: false,
        EcommercePriceListId: ""
      }
    }
  },

  methods: {
    save() {
      this.$emit("save");
    },
    validate() {
      let errors = "";
      let valid = true;

      if (this.$isNullOrEmpty(this.priceList.Name)) {
        errors += "- <b>Descrição</b> é obrigatória";
        valid = false;
      }
      if (this.$isNullOrEmpty(this.priceList.PriceListCode)) {
        errors += "<br>- <b>Código</b> é obrigatório";
        valid = false;
      }

      valid = !errors.length > 0;

      if (!valid)
        this.$message({
          dangerouslyUseHTMLString: true,
          type: 'error',
          duration: 3000,
          message: errors
        });

      return valid;
    },
    getValue() {
      return this.priceList;
    },
    load() {
      this.priceList = this.priceListCurrent;
    },
    showOnlyForSAPHANA() {
      let platform = this.ecommerce.Erp.Platform;
      this.isSAPHANA = ("Sap Hana").includes(constants.ErpPlatform.find((a) => a.Value === platform).Label);

      return this.isSAPHANA;
    },
    priceListCodeSelect() {
      this.priceList.Name = this.erpPriceLists.find(x => x.Code == this.priceList.PriceListCode).Name;
    }
  },
  mounted() {
    EventBus.$on("loadPriceList", () => this.load());
  }
}
</script>
<style>
.el-switch.is-checked .el-switch__core {
  border-color: #00ADB7 !important;
  background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
  color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
  color: #00ADB7;
  font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
  cursor: not-allowed !important;
}
</style>
