<template>
    <Modal :modalContentClasses="'card'" :showClose="false" :show="show">
        <h4 slot="header" class="card-title">Adicionar condição</h4>
        <base-input>
            <label>Tipo de documento:</label>
            <el-select v-model="fulfillmentConfigurationCondition.DocumentType" placeholder="Selecione o tipo de documento"
                @change="selectDocumentType">
                <el-option v-for="item in typeOptionsDocument" :key="item.Key" :label="item.Label" :value="item.Key">
                </el-option>
            </el-select>
        </base-input>
        <base-input>
            <label>ID do utilização:</label>
            <el-input v-model="fulfillmentConfigurationCondition.SapUsage" placeholder="Informe o ID correspondente"
                type="number" />
        </base-input>
        <base-input>
            <label>ID do depósito:</label>
            <el-input v-model="fulfillmentConfigurationCondition.WarehouseCode" placeholder="Informe o ID correspondente" />
        </base-input>
        <base-input v-if="fulfillmentConfigurationCondition.DocumentType == 13">
            <label>Tipo de pagamento:</label>
            <el-select class="select-primary mb-3 pagination-select"
                v-model="fulfillmentConfigurationCondition.PaymentMethodType"
                placeholder="Informe o Tipo do Contas a Pagar">
                <el-option class="select-primary" v-for="item in paymentMethodAccountTypes" :key="item.Key"
                    :label="item.Label" :value="item.Key"></el-option>
            </el-select>
        </base-input>
        <base-input v-if="['transfer', 'creditCard'].includes(fulfillmentConfigurationCondition.PaymentMethodType)">
            <label>Conta do Razão:</label>
            <el-input placeholder="Informe a Conta do Razão" v-model="fulfillmentConfigurationCondition.Account" />
        </base-input>
        <base-input>
            <label>Tipo de depósito:</label>
            <el-select v-model="fulfillmentConfigurationCondition.WarehouseType" placeholder="Selecione o tipo de depósito">
                <el-option v-for="item in typeOptionsWarehouse" :key="item.Key" :label="item.Label" :value="item.Key">
                </el-option>
            </el-select>
        </base-input>
        <base-input v-if="fulfillmentConfigurationCondition.DocumentType == 12">
            <label>Utilizar itens alternativos</label>
            <br>
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                v-model="fulfillmentConfigurationCondition.UseAlternativeItems" :sync="true" />
        </base-input>
        <base-input v-if="fulfillmentConfigurationCondition.DocumentType == 12">
            <label>Validar Item Serie Stock By OSBQ</label>
            <br>
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                v-model="fulfillmentConfigurationCondition.ValidateItemSerieStockByOSBQ" :sync="true" />
        </base-input>
        <base-input v-if="[11, 12].includes(fulfillmentConfigurationCondition.DocumentType)">
            <label>FillINV12</label>
            <br>
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                v-model="fulfillmentConfigurationCondition.FillINV12" :sync="true" />
        </base-input>
        <base-input v-if="[11].includes(fulfillmentConfigurationCondition.DocumentType)">
            <label>Ativar o custo de devolução</label>
            <br>
            <toggle-button :labels="{ checked: 'ON', unchecked: 'OFF' }" color="#7dd7dc" input type="checkbox"
                v-model="fulfillmentConfigurationCondition.EnableReturnCost" :sync="true" />
        </base-input>
        <br>
        <div class="d-flex justify-content-between">
            <el-button type="primary" @click="addFulfillmentConfigurationCondition" class="ml-auto"
                :disabled="disabledAdd">Adicionar</el-button>
            <el-button @click="closeModal" style="margin-left: 10px;">Cancelar</el-button>
        </div>
    </Modal>
</template>
<script>
import Modal from '@/components/Modal';
import constants from '@/util/constants';

export default {
    props: {
        show: {
            type: Boolean,
            default: false
        },
    },
    components: {
        Modal
    },
    data() {
        return {
            fulfillmentConfigurationCondition: {
                DocumentType: 0,
                SapUsage: null,
                WarehouseCode: null,
                WarehouseType: 0,
                UseAlternativeItems: false,
                ValidateItemSerieStockByOSBQ: false,
                FillINV12: false,
                EnableReturnCost: false
            },
            typeOptionsDocument: [],
            typeOptionsWarehouse: [],
            disabledAdd: true,
            paymentMethodAccountTypes: constants.PaymentMethodTypes,
        }
    },
    methods: {
        addFulfillmentConfigurationCondition() {
            if (!this.validate())
                return;

            this.$emit("evtAddedCondition", this.fulfillmentConfigurationCondition);
            this.fulfillmentConfigurationCondition = {
                DocumentType: 0,
                SapUsage: null,
                WarehouseCode: null,
                WarehouseType: 0,
                BPLId: 0
            }
        },
        closeModal() {
            this.$emit("evtClose");
        },
        loadTypeOptions() {
            this.typeOptionsDocument = constants.fulfillmentConfigurationDocumentOptions;
            this.typeOptionsWarehouse = constants.fulfillmentConfigurationWarehouseOptions;
        },
        selectDocumentType(type) {
            this.disabledAdd = type > 0 ? false : true;
        },
        validate() {
            if ([2, 8, 10].includes(this.fulfillmentConfigurationCondition.DocumentType) &&
                this.$isNullOrEmpty(this.fulfillmentConfigurationCondition.SapUsage)) {
                this.$showError("ID do utilização obrigatório.");
                return false;
            } else if ([2, 8, 9, 10].includes(this.fulfillmentConfigurationCondition.DocumentType) &&
                this.$isNullOrEmpty(this.fulfillmentConfigurationCondition.WarehouseCode)) {
                this.$showError("ID do depósito obrigatório.");
                return false;
            } else if ([9].includes(this.fulfillmentConfigurationCondition.DocumentType) &&
                this.fulfillmentConfigurationCondition.WarehouseType === 0) {
                this.$showError("Tipo de depósito obrigatório.");
                return false;
            }
            else
                return true;
        },
    },
    mounted() {
        this.loadTypeOptions();
        this.dialogVisible = true;
    },
    destroyed() {
        this.dialogVisible = false;
    }
}
</script>
<style>
.modal-footer button {
    margin: 10;
    padding-left: 16px;
    padding-right: 16px;
    width: auto;
}

.el-button--primary.is-disabled,
.el-button--primary.is-disabled:active,
.el-button--primary.is-disabled:focus,
.el-button--primary.is-disabled:hover {
    color: #FFF;
    background-color: #7ed3d8;
    border-color: #7ed3d8;
}

.el-button:focus,
.el-button:hover {
    color: #FFF;
    border-color: #00ADB7;
    background-color: #00ADB7;
}

.el-button--primary {
    color: #FFF;
    background-color: #4eacb1;
    border-color: #4eacb1;
}

.el-switch.is-checked .el-switch__core {
    border-color: #00ADB7 !important;
    background-color: #00ADB7 !important;
}

.el-switch__label.is-active {
    color: #00ADB7 !important;
}

.el-select-dropdown__item.selected {
    color: #00ADB7;
    font-weight: 700;
}

.form-group .el-select .el-input.is-disabled .el-input__inner {
    cursor: not-allowed !important;
}
</style>
