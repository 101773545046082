<template>
  <div class="flex-fill card-body">
    <div class="row">
      <div class="col-6">
        <base-input
          label="Título da Página"
          v-model="form.title"
          :required="scheduleSyncProductsActive"
          :error="messageError(v$.form.title)"
        />
      </div>
      <div class="col-6">
        <base-input
          label="Text Link"
          v-model="form.link"
          :required="scheduleSyncProductsActive"
          :error="messageError(v$.form.link)"
        />
      </div>
    </div>
    <div class="d-flex">
      <div class="flex-fill" align="center">
        <h5 style="margin-left: 10px">Exibir no site</h5>
        <toggle-button
          :labels="{ checked: 'ON', unchecked: 'OFF' }"
          color="#7dd7dc"
          input
          type="checkbox"
          v-model="form.showSite"
          :sync="true"
        />
      </div>
      <div class="flex-fill" align="center">
        <h5 style="margin-left: 10px">Exibir produto esgotado</h5>
        <toggle-button
          :labels="{ checked: 'ON', unchecked: 'OFF' }"
          color="#7dd7dc"
          input
          type="checkbox"
          v-model="form.showEmptyStock"
          :sync="true"
        />
      </div>
      <div class="flex-fill" align="center">
        <h5 style="margin-left: 10px">Exibir no marketplace</h5>
        <toggle-button
          :labels="{ checked: 'ON', unchecked: 'OFF' }"
          color="#7dd7dc"
          input
          type="checkbox"
          v-model="form.showMarketplace"
          :sync="true"
        />
      </div>
      <div class="flex-fill" align="center">
        <h5 style="margin-left: 10px">Exibir SPOT</h5>
        <toggle-button
          :labels="{ checked: 'ON', unchecked: 'OFF' }"
          color="#7dd7dc"
          input
          type="checkbox"
          v-model="form.showSpot"
          :sync="true"
        />
      </div>
      <div class="flex-fill" align="center">
        <h5 style="margin-left: 10px">Exibir na BuyBox</h5>
        <toggle-button
          :labels="{ checked: 'ON', unchecked: 'OFF' }"
          color="#7dd7dc"
          input
          type="checkbox"
          v-model="form.showBuyBox"
          :sync="true"
        />
      </div>
      <div class="flex-fill" align="center">
        <h5 style="margin-left: 10px">Destaque na home</h5>
        <toggle-button
          :labels="{ checked: 'ON', unchecked: 'OFF' }"
          color="#7dd7dc"
          input
          type="checkbox"
          v-model="form.showHome"
          :sync="true"
        />
      </div>
      <div class="flex-fill" align="center">
        <h5 style="margin-left: 10px">Aceita contraproposta</h5>
        <toggle-button
          :labels="{ checked: 'ON', unchecked: 'OFF' }"
          color="#7dd7dc"
          input
          type="checkbox"
          v-model="form.acceptProposal"
          :sync="true"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { EventBus } from "@/eventBus";
import { Select, Option } from "element-ui";
import validateMixin from "@/mixins/validateMixin";
import { helpers, required, requiredIf } from "@vuelidate/validators";
import { mapState, mapMutations } from 'vuex';

export default {
  mixins : [validateMixin],
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },
  data() {
    return {
      form: {
        title: "",
        link: "",
        showSite: false,
        showEmptyStock: false,
        showMarketplace: false,
        showSpot: false,
        showBuyBox: false,
        showHome: false,
        acceptProposal: false,
      },
      freeShippingOptions: [
        {
          label: "Neutro",
          value: 0,
        },
      ],
      productConditions: [
        {
          label: "Novo",
          value: 0,
        },
        {
          label: "Usado",
          value: 1,
        },
      ],
      formChanged: false,
      renderedComponent: false
    };
  },
  validations() {
    return {
      form: {
        title: {
          required: helpers.withMessage(
            "Titulo da Página obrigatório",
            requiredIf(function () {
               return this.scheduleSyncProductsActive
         })),
        },
        link: {
          required: helpers.withMessage("Text Link obrigatório", requiredIf(function () {
               return this.scheduleSyncProductsActive
         })),
        },
      },
    };
  },
  computed: {
    ...mapState("product",["productCurrent","scheduleSyncProductsActive"])
  },
  methods: {
    ...mapMutations('product',['SET_FORM_CHANGED']),
    value() {
      return { showCase : {...this.form} };
    },
    fillForm(){
      if(this.productCurrent && this.productCurrent.ShowCase ){
        this.form.id = this.productCurrent.ShowCase.Id;
        this.form.title = this.productCurrent.ShowCase.Title;
        this.form.link = this.productCurrent.ShowCase.Link;
        this.form.showSite = this.productCurrent.ShowCase.ShowSite;
        this.form.showEmptyStock = this.productCurrent.ShowCase.ShowEmptyStock;
        this.form.showMarketplace = this.productCurrent.ShowCase.ShowMarketplace;
        this.form.showSpot = this.productCurrent.ShowCase.ShowSpot;
        this.form.showBuyBox = this.productCurrent.ShowCase.ShowBuyBox;
        this.form.showHome = this.productCurrent.ShowCase.ShowHome;
        this.form.acceptProposal = this.productCurrent.ShowCase.AcceptProposal;
      }
      this.formChanged = false;
    }
  },
  created() {
    EventBus.$on("ProductRegisterEdition", () =>  this.fillForm());
  },
  watch: {
    form: {
      deep: true,
      handler(newValue, oldValue) {
        if(this.formChanged == false && this.renderedComponent)
        {
          this.formChanged = true;
          this.SET_FORM_CHANGED(true);
        }
      }
    }
  },
  mounted(){
   setTimeout(() => {
      this.renderedComponent = true;
    }, 3000);
  }
};
</script>
<style scoped>
.card-body {
  margin: 10px;
  border-width: 0.5px;
  border-style: solid;
  border-radius: 5px;
}
</style>