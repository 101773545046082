<template>
    <card type="tasks" class="stacked-form">
        <div class="d-flex justify-content-between">
            <h4 class="card-title">Condições</h4>
            <base-button type="primary" small class="btn-next" @click="showModalCondition">
                Adicionar
            </base-button>
        </div>
        <div class="col-12">
            <el-table :data="listConditions">
                <el-table-column label="Tipo de documento">
                    <div slot-scope="props">
                        <base-input>
                            <el-select class="select-primary" v-model="props.row.DocumentType">
                                <el-option v-for="item in typeOptionsDocument" :key="item.Key" :label="item.Label"
                                    :value="item.Key"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                </el-table-column>
                <el-table-column label="Utilização">
                    <div slot-scope="props">
                        <base-input v-model="props.row.SapUsage" />
                    </div>
                </el-table-column>
                <el-table-column label="Depósito">
                    <div slot-scope="props">
                        <base-input>
                            <el-input v-model="props.row.WarehouseCode" />
                        </base-input>
                    </div>
                </el-table-column>
                <el-table-column label="Tipo Depósito">
                    <div slot-scope="props">
                        <base-input>
                            <el-select class="select-primary" v-model="props.row.WarehouseType">
                                <el-option v-for="item in typeOptionsWarehouse" :key="item.Key" :label="item.Label"
                                    :value="item.Key"></el-option>
                            </el-select>
                        </base-input>
                    </div>
                </el-table-column>
                <el-table-column :min-width="135" align="right" label="Ações">
                    <div slot-scope="props">
                        <base-button class="like !important btn-link" type="danger" size="sm" icon
                            @click="removeCondition(props.row)">
                            <i class="material-icons">delete_outline</i>
                        </base-button>
                    </div>
                </el-table-column>
            </el-table>
        </div>
        <FulfillmentConfigurationConditionModal :show="showModal" @evtAddedCondition="saveCondition"
            @evtClose="closeModalCondition" />
    </card>
</template>

<script>

import constants from '@/util/constants'
import FulfillmentConfigurationConditionModal from './FulfillmentConfigurationConditionModal'

export default {
    name: "FulfillmentConfigurationCondition",
    components: {
        FulfillmentConfigurationConditionModal
    },
    props: {
        conditions: {
            type: Array
        }
    },
    data() {
        return {
            listConditions: [],
            showModal: false,
            typeOptionsDocument: constants.fulfillmentConfigurationDocumentOptions,
            typeOptionsWarehouse: constants.fulfillmentConfigurationWarehouseOptions,
        }
    },
    methods: {
        showModalCondition() {
            this.showModal = true;
        },
        saveCondition(model) {
            this.listConditions.push({ ...model });
            this.showModal = false;
        },
        closeModalCondition() {
            this.showModal = false;
        },
        removeCondition(index) {
            this.listConditions.splice(index, 1);
        },
        value() {
            return this.listConditions;
        },
    },
    created() {
        this.listConditions = this.conditions;
    },
    watch: {
        conditions(newVal) {
            this.listConditions = newVal;
        }
    }
}
</script>
