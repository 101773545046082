<template>
    <div class="row">
        <div class="col-12">
            <CleaningLogForm :cleaningLogs="cleaningLogs" />
        </div>
        <div class="col-12">
            <UpdateLog :dataLog="logs" />
        </div>
        <div class="col-12">
            <div class="d-flex justify-content-center justify-content-between flex-wrap">
                <div></div>
                <div>
                    <button class="custom-btn-save" @click="update">Atualizar</button>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { mapActions, mapState } from "vuex";
import CleaningLogForm from "../components/CleaningLogForm.vue";
import UpdateLog from "../../../components/UpdateLog.vue";

export default {
    components: {
        CleaningLogForm,
        UpdateLog
    },
    data() {
        return {
            selectedEcomm: ""
        };
    },
    computed: {
        ...mapState('cleaningLog', ['cleaningLogs', 'logs']),
        ...mapState(['realm', 'ecommerce'])
    },
    methods: {
        ...mapActions("cleaningLog", ["getCleaningLogs", 'updateCleaningLogs', 'getLogs']),
        async load() {
            await this.getCleaningLogs(this.$store.state.ecommerce.Name);
            await this.getLogs();
        },
        async update() {
            this.updateCleaningLogs(this.cleaningLogs).then(async () => {
                this.$showSuccess("O cadastro foi atualizado com sucesso");
                await this.getLogs();
            }).catch(error => { this.$showError(error) });
        }
    },
    async mounted() {
        await this.load();
    },
}
</script>
<style scoped></style>