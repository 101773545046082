<template>
  <div>
    <el-tabs>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-info"></i> Informações Gerais</span>
        <ProductGeneralInformation ref="generalInformation" />
        <ProductIntegration v-if="typeForm === 'edition'" />
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-s-shop"></i> Apresentação</span>
        <ProductPresentation ref="presentation" />
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-s-operation"></i> Atributos</span>
        <ProductAttributes ref="attributes" />
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-s-unfold"></i> SKUs</span>
        <ProductSkus ref="skus" />
      </el-tab-pane>
      <el-tab-pane>
        <span slot="label"><i class="el-icon-s-unfold"></i> KITs</span>
        <ProductKit ref="kit" />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import ProductGeneralInformation from "@/pages/Product/components/ProductGeneralInformation";
import ProductAttributes from "@/pages/Product/components/ProductAttributes";
import ProductIntegration from "@/pages/Product/components/ProductIntegration";
import ProductPresentation from "@/pages/Product/components/ProductPresentation";
import ProductSkus from "@/pages/Product/components/ProductSkus";
import ProductKit from "@/pages/Product/components/ProductKit";
import constants from "@/util/constants";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    ProductGeneralInformation,
    ProductAttributes,
    ProductIntegration,
    ProductPresentation,
    ProductSkus,
    ProductKit
  },
  props: {
    typeForm: {
      type: String,
      default: constants.typeForm.create,
    },
  },
  computed: {
    ...mapState("product", ["productCurrent", "scheduleSyncProductsActive"]),
  },
  async mounted() {
    await this.$bahngleis.get(`RecurringJobs?ecommerceName=${this.$store.state.ecommerce.Name}`).then(response => {
      let schedule = response.data.find(a => a.Name === 'SyncProducts');
      this.SET_SCHEDULE_SYNC_PRODUCTS_ACTIVE(schedule && schedule.Active ? true : false);
    });
  },
  methods: {
    ...mapMutations('product', ['SET_SCHEDULE_SYNC_PRODUCTS_ACTIVE']),
    isFormValid() {
      if (Object.values(this.$refs).some((ref) => !ref.validate()))
        return false;
      else return true;
    },
    prepareFormToSend() {
      let payload = {};
      Object.values(this.$refs).forEach((ref) => {
        const refValue = ref.value();
        const refName = Object.keys(refValue)[0];

        if (payload.hasOwnProperty(refName) && Array.isArray(payload[refName])) {
          payload[refName].push(...refValue[refName]);
        } else
          Object.assign(payload, ref.value())
      }
      );

      return payload;
    },
  },
};
</script>
<style>
.el-tabs__item {
  color: #eff6f7 !important;
}

.el-tabs__item.is-active {
  color: #00adb7 !important;
}

.el-tabs__item:hover {
  color: #00adb7 !important;
}

.el-tabs__active-bar {
  background-color: #00adb7 !important;
}
</style>
