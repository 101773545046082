<template>
    <el-dialog title="Atualizar Status" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
        <div class="d-flex align-items-center ml-2">
            <label>Especificar pedido(s)?</label>
            <toggle-button style="width:55px" class="ml-2" :labels="{ checked: 'SIM', unchecked: 'NÃO' }" color="#7dd7dc"
                input type="checkbox" v-model="showTextArea" :sync="true" />
        </div>

        <div v-if="showTextArea">
            <span>Especifique o(s) número(s) do(s) pedido(s) - cada um por linha</span>
            <div class="d-flex">
                <el-input placeholder="Pedido(s)" rows="5" type="textarea" v-model="number"></el-input>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
            <el-button @click="handleClose">Cancelar</el-button>
            <el-button type="primary" @click="save">Sincronizar</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Modal from '@/components/Modal'
export default {
    components: [
        Modal
    ],
    components: { Modal },
    data() {
        return {
            dialogVisible: false,
            number: null,
            orders: [],
            recurringJob: null,
            title: "",
            showTextArea: false,
            type: null
        }
    },
    methods: {
        handleClose() {
            this.$router.go(-1);
        },
        clearNumber() {
            this.number = null;
        },
        save() {
            if (this.showTextArea && this.$isNullOrEmpty(this.number)) {
                this.$showError("Nenhum pedido especificado!");
                return;
            }

            if (!this.$isNullOrEmpty(this.number))
                this.orders = this.number.split("\n");

            this.$bahngleis.post(`Order/manual-status-refresh?ecommerceName=${this.$store.state.ecommerce.Name}`, this.orders);

            this.$showSuccess("Pedido de atualização iniciada com sucesso!");

            this.handleClose();
        },
    },
    mounted() {
        this.clearNumber();
        this.dialogVisible = true;

    },
    destroyed() {
        this.dialogVisible = false;
    },
}
</script>
